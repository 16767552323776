import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExternalRedirectsService {

  urlsToRedirect = [
    {
      from: '/villiersdorp-main-road-financial-planning',
      to: '/branch-office/villiersdorp-main-road-financial-planning'
    },
    {
      from: '/mossel-bay-church-street',
      to: '/branch-office/mossel-bay-church-street'
    },
    {
      from: '/firestationsaxonwoldstockbrokingbrucewolov',
      to: '/branch-office/firestation-saxonwold-stockbroking-bruce-wolov'
    },
    {
      from: '/lichtenburg-nelson-mandela-road-financial-planning',
      to: '/branch-office/lichtenburg-nelson-mandela-road-financial-planning'
    },
    {
      from: '/ellisras-well-street',
      to: '/branch-office/ellisras-well-street'
    },
    {
      from: '/potchefstroom-3-palmiet-street-financial-planning',
      to: '/branch-office/potchefstroom-3-palmiet-street-financial-planning'
    },
    {
      from: '/bloemfontein-victoria-road',
      to: '/branch-office/bloemfontein-victoria-road'
    },
    {
      from: '/aliwal-north-bank-street',
      to: '/branch-office/aliwal-north-bank-street'
    },
    {
      from: '/beaufortwesmakelaarsdienste',
      to: '/branch-office/beaufort-west-donkin-street'
    },
    {
      from: '/stellenboschdorpstreet',
      to: '/branch-office/stellenbosch-dorp-street'
    },
    {
      from: '/moreleta-park-midas-avenue',
      to: '/branch-office/moreleta-park-midas-avenue'
    },
    {
      from: '/windhoek',
      to: '/branch-office/windhoek'
    },
    {
      from: '/somerset-west-mall-ring-road-financial-planning',
      to: '/branch-office/somerset-west-mall-ring-road-financial-planning'
    },
    {
      from: '/roodepoort-constantia-office-park-handre-janse-van-rensburg',
      to: '/branch-office/roodepoort-constantia-office-park-handre-janse-van-rensburg'
    },
    {
      from: '/stellenbosch-church-street',
      to: '/branch-office/stellenbosch-church-street'
    },
    {
      from: '/uitenhage-graaff-reinet-road',
      to: '/branch-office/uitenhage-graaff-reinet-road'
    },
    {
      from: '/upington-van-coppenhagen-street',
      to: '/branch-office/upington-van-coppenhagen-street'
    },
    {
      from: '/paarl-main-road-211-financial-planning-manie-malan',
      to: '/branch-office/paarl-main-road-211-financial-planning-manie-malan'
    },
    {
      from: '/hatfield-colbyn-amos-street',
      to: '/branch-office/hatfield-colbyn-amos-street'
    },
    {
      from: '/verrenoord',
      to: '/branch-office/louis-trichardt'
    },
    {
      from: '/willowbridgecarlcronjedrivefinancialplanning',
      to: '/branch-office/willowbridge-carl-cronje-drive-financial-planning'
    },
    {
      from: '/vredenburgvoortrekkerroad',
      to: '/branch-office/vredenburg-voortrekker-street'
    },
    {
      from: '/malmesbury-market-street',
      to: '/branch-office/malmesbury-market-street'
    },
    {
      from: '/kuruman-seodin-road',
      to: '/branch-office/kuruman-seodin-road'
    },
    {
      from: '/east-rand',
      to: '/branch-office/east-rand'
    },
    {
      from: '/graaffreinet',
      to: '/branch-office/graaff-reinet-bourke-street-short-term'
    },
    {
      from: '/piet-retief',
      to: '/branch-office/piet-retief'
    },
    {
      from: '/springbok-van-riebeeck-street',
      to: '/branch-office/springbok-keerom-street'
    },
    {
      from: '/cradock-adderley-street',
      to: '/branch-office/cradock-adderley-street'
    },
    {
      from: '/middelburg-mpumalanga',
      to: '/branch-office/middelburg-mpumalanga'
    },
    {
      from: '/tzaneen-agatha-street',
      to: '/branch-office/tzaneen-agatha-street'
    },
    {
      from: '/welkom-stateway',
      to: '/branch-office/welkom-stateway'
    },
    {
      from: '/harrismith-stuart-street',
      to: '/branch-office/harrismith-stuart-street'
    },
    {
      from: '/mokopane',
      to: '/branch-office/mokopane-thabo-mbeki-way'
    },
    {
      from: '/psgonline',
      to: '/branch-office/psg-securities'
    },
    {
      from: '/empangeni-norman-tedder-lane-financial-planning',
      to: '/branch-office/empangeni-norman-tedder-lane-financial-planning'
    },
    {
      from: '/kenak',
      to: '/branch-office/kenak'
    },
    {
      from: '/de-aar-main-road',
      to: '/branch-office/de-aar-main-road'
    },
    {
      from: '/upington-anton-lubowski-street-short-term',
      to: '/branch-office/upington-anton-lubowski-street-short-term'
    },
    {
      from: '/grahamstown',
      to: '/branch-office/grahamstown-oatlands-road'
    },
    {
      from: '/gansbaai-main-road',
      to: '/branch-office/gansbaai-main-road'
    },
    {
      from: '/somerset-links',
      to: '/branch-office/somerset-links'
    },
    {
      from: '/george-york-street',
      to: '/branch-office/george-york-street'
    },
    {
      from: '/menlo-park-glenwood-road',
      to: '/branch-office/menlo-park-glenwood-road'
    },
    {
      from: '/ficksburg-piet-retief-street-short-term',
      to: '/branch-office/ficksburg-piet-retief-street-short-term'
    },
    {
      from: '/hermanus-dirkie-uys-street-financial-planning',
      to: '/branch-office/hermanus-dirkie-uys-street-financial-planning'
    },
    {
      from: '/nylstroom-thabo-mbeki-road-98-short-term',
      to: '/branch-office/nylstroom-thabo-mbeki-road-98-short-term'
    },
    {
      from: '/port-elizabeth-146-cape-road-portfolio-managers',
      to: '/branch-office/pe-investments'
    },
    {
      from: '/pretoria-oos-pretoria-east',
      to: '/branch-office/pretoria-oos-pretoria-east'
    },
    {
      from: '/port-elizabeth-conyngham-road',
      to: '/branch-office/port-elizabeth-conyngham-road'
    },
    {
      from: '/menlyn',
      to: '/branch-office/menlyn'
    },
    {
      from: '/kimberley-jan-van-zyl-street-financial-planning-piet-theron',
      to: '/branch-office/kimberley-jan-van-zyl-street-financial-planning-piet-theron'
    },
    {
      from: '/vredendal',
      to: '/branch-office/vredendal-voortrekkerstraat'
    },
    {
      from: '/douglas-campbell-street',
      to: '/branch-office/douglas-campbell-street'
    },
    {
      from: '/patensie',
      to: '/branch-office/patensie-fred-ferreira-road'
    },
    {
      from: '/rustenburg-kock-street-christoff-steenkamp',
      to: '/branch-office/rustenburg-kock-street-christoff-steenkamp'
    },
    {
      from: '/newlands-lois-avenue-financial-planning',
      to: '/branch-office/newlands-lois-avenue-financial-planning'
    },
    {
      from: '/lephalale-patrys-street',
      to: '/branch-office/lephalale-patrys-street'
    },
    {
      from: '/bredasdorp-long-street',
      to: '/branch-office/bredasdorp-long-street'
    },
    {
      from: '/vaalwater-sanddrif-road',
      to: '/branch-office/vaalwater-sanddrif-road'
    },
    {
      from: '/levubu',
      to: '/branch-office/levubu'
    },
    {
      from: '/east-london-sansom-road-financial-planning',
      to: '/branch-office/east-london-sansom-road-financial-planning'
    },
    {
      from: '/newlands-constantia',
      to: '/branch-office/newlands-constantia'
    },
    {
      from: '/middelburg-van-reenen-street',
      to: '/branch-office/middelburg-van-reenen-street'
    },
    {
      from: '/vereeniging-rhodes-avenue',
      to: '/branch-office/vereeniging-rhodes-avenue'
    },
    {
      from: '/peshortterm',
      to: '/branch-office/pe-short-term'
    },
    {
      from: '/umhlanga-ridge',
      to: '/branch-office/umhlanga-ridge'
    },
    {
      from: '/psg-trust',
      to: '/branch-office/psg-trust'
    },
    {
      from: '/tyger-valley',
      to: '/branch-office/tyger-valley'
    },
    {
      from: '/bedfordview',
      to: '/branch-office/bedfordview'
    },
    {
      from: '/hmns',
      to: '/branch-office/hermanus-portfolio-management-and-stockbroking'
    },
    {
      from: '/wellington-rose-street-financial-planning',
      to: '/branch-office/wellington-rose-street-financial-planning'
    },
    {
      from: '/pe-brokerage',
      to: '/branch-office/pe-brokerage'
    },
    {
      from: '/kakamas-stasie-way',
      to: '/branch-office/kakamas-stasie-way'
    },
    {
      from: '/port-alfred-campbell-street',
      to: '/branch-office/port-alfred-campbell-street'
    },
    {
      from: '/eastlondonshortterm',
      to: '/branch-office/east-london-stewart-drive-short-term-angie-geyer'
    },
    {
      from: '/melrose-arch',
      to: '/branch-office/melrose-arch'
    },
    {
      from: '/silverlakes',
      to: '/branch-office/silver-lakes-wealth-management-&-stockbroking'
    },
    {
      from: '/northcliffstockbroking',
      to: '/branch-office/northcliff-stockbroking-jacques-snyman'
    },
    {
      from: '/psgkonsultwarmbad',
      to: '/branch-office/warmbad-luna-road-short-term-johan-eksteen'
    },
    {
      from: '/mossel-bay-meyer-street',
      to: '/branch-office/mossel-bay-meyer-street'
    },
    {
      from: '/pe-trust',
      to: '/branch-office/pe-trust'
    },
    {
      from: '/illovomelvilleroad',
      to: '/branch-office/illovo-melville-road-financial-planning'
    },
    {
      from: '/bloemfontein-preller-walk-financial-planning-rynoe-smith',
      to: '/branch-office/bloemfontein-preller-walk-financial-planning-rynoe-smith'
    },
    {
      from: '/vryheid-president-street',
      to: '/branch-office/vryheid-president-street'
    },
    {
      from: '/psg-cascades',
      to: '/branch-office/psg-cascades'
    },
    {
      from: '/vanderbijlpark',
      to: '/branch-office/vanderbijlpark-financial-planning'
    },
    {
      from: '/ficksburg-piet-retief-street-financial-planning',
      to: '/branch-office/ficksburg-piet-retief-street-financial-planning'
    },
    {
      from: '/meesterplan',
      to: '/branch-office/meesterplan'
    },
    {
      from: '/meesterplan-portfolio-management-and-financial-planning',
      to: '/branch-office/meesterplan-portfolio-management-and-financial-planning'
    },
    {
      from: '/nylstroom-kokanje-retirement-centre',
      to: '/branch-office/nylstroom-kokanje-retirement-centre'
    },
    {
      from: '/lynnwood-alpine-way',
      to: '/branch-office/lynnwood-alpine-way'
    },
    {
      from: '/psgkonsulttrust3',
      to: '/branch-office/sandton-trust'
    },
    {
      from: '/pe-financial-planning',
      to: '/branch-office/pe-financial-planning'
    },
    {
      from: '/brits',
      to: '/branch-office/brits'
    },
    {
      from: '/vredenburg-heuningklip-street',
      to: '/branch-office/vredenburg-heuningklip-street'
    },
    {
      from: '/umhlanga-portfolio-management',
      to: '/branch-office/umhlanga-portfolio-management'
    },
    {
      from: '/sandton-peter-road-stockbroking',
      to: '/branch-office/sandton-peter-road-stockbroking'
    },
    {
      from: '/cape-town-strand-street',
      to: '/branch-office/cape-town-strand-street'
    },
    {
      from: '/georgecentral',
      to: '/branch-office/george-central-portfolio-management'
    },
    {
      from: '/trustgeorge',
      to: '/branch-office/george-central-fiduciary'
    },
    {
      from: '/psgkstadministrationmanagement',
      to: '/branch-office/psg-st-administration-management'
    },
    {
      from: '/wzwellnessmatterscc3',
      to: '/branch-office/midlands-employee-benefits'
    },
    {
      from: '/mokopane-pretorius-street',
      to: '/branch-office/mokopane-pretorius-street'
    },
    {
      from: '/tygervalley-stockbroking-and-financial-planning',
      to: '/branch-office/tygervalley-stockbroking-and-financial-planning'
    },
    {
      from: '/bloemfontein-nelson-mandela-street-short-term-hanlie-smit',
      to: '/branch-office/bloemfontein-preller-walk-short-term-hanlie-smit'
    },
    {
      from: '/bloemfontein-preller-walk-financial-planning-carel-botma',
      to: '/branch-office/bloemfontein-preller-walk-financial-planning-carel-botma'
    },
    {
      from: '/bloemfonteindanpienaar',
      to: '/branch-office/bloemfontein-dan-pienaar'
    },
    {
      from: '/featherbrooke-traded-policies',
      to: '/branch-office/featherbrooke-traded-policies'
    },
    {
      from: '/roodepoort-constantia-office-park-gideon-steyn',
      to: '/branch-office/roodepoort-constantia-office-park-gideon-steyn'
    },
    {
      from: '/clearwater-portfolio-management',
      to: '/branch-office/clearwater-portfolio-management'
    },
    {
      from: '/psgkstadministrationclaims',
      to: '/branch-office/psg-st-administration-claims'
    },
    {
      from: '/midlandshealthcare',
      to: '/branch-office/midlands-employee-benefits'
    },
    {
      from: '/clearwater-stockbroking',
      to: '/branch-office/clearwater-stockbroking'
    },
    {
      from: '/wzwellnessmatterscc4',
      to: '/branch-office/midlands-employee-benefits'
    },
    {
      from: '/pretoria-boardwalk',
      to: '/branch-office/pretoria-boardwalk'
    },
    {
      from: '/midlandshealthcare1',
      to: '/branch-office/midlands-employee-benefits'
    },
    {
      from: '/sandton-financial-planning-and-wealth-management',
      to: '/branch-office/sandton-financial-planning-and-wealth-management'
    },
    {
      from: '/newcastle-paterson-street',
      to: '/branch-office/newcastle-gemsbok-street-financial-planning-jurgen-meyer'
    },
    {
      from: '/psgkstadministrationcompliance',
      to: '/branch-office/psgk-st-administration-compliance'
    },
    {
      from: '/rietondale-soutpansberg-road',
      to: '/branch-office/rietondale-soutpansberg-road'
    },
    {
      from: '/port-elizabeth-281-cape-road',
      to: '/branch-office/port-elizabeth-281-cape-road'
    },
    {
      from: '/uitenhagemoselroad',
      to: '/branch-office/uitenhage-mosel-road'
    },
    {
      from: '/centurion-witch-hazel-avenue',
      to: '/branch-office/centurion-witch-hazel-avenue'
    },
    {
      from: '/midlands-fp',
      to: '/branch-office/pietermaritzburg-town-bush-cascades-financial-planning'
    },
    {
      from: '/portshepstone',
      to: '/branch-office/shelly-beach-marine-drive-short-term'
    },
    {
      from: '/fairlandfinancialplanning',
      to: '/branch-office/roodepoort-constantia-office-park-marthinus-muller'
    },
    {
      from: '/christiana-william-alexander-street',
      to: '/branch-office/christiana-william-alexander-street'
    },
    {
      from: '/robertsonreitzstreetshortterm',
      to: '/branch-office/robertson-reitz-street-short-term'
    },
    {
      from: '/worcesterhighstreetcommercialandpersonalinsurance',
      to: '/branch-office/worcester-adderley-street-short-term'
    },
    {
      from: '/witbank-van-ryneveld-street',
      to: '/branch-office/witbank-van-ryneveld-street'
    },
    {
      from: '/newcastle-gemsbok-street-short-term',
      to: '/branch-office/newcastle-gemsbok-street-short-term'
    },
    {
      from: '/hartbeespoort-beethoven-street-financial-planning',
      to: '/branch-office/hartbeespoort-beethoven-street-financial-planning-and-stockbroking'
    },
    {
      from: '/psgassetmanagementptyltd',
      to: '/branch-office/psg-asset-management-pty-ltd'
    },
    {
      from: '/constantiapsgassetmanagementgroupservices',
      to: '/branch-office/constantia-psg-asset-management-group-services'
    },
    {
      from: '/constantiapsgassetmanagement',
      to: '/branch-office/constantia-psg-asset-management'
    },
    {
      from: '/constantiapsgcollectiveinvestments',
      to: '/branch-office/constantia-psg-collective-investments'
    },
    {
      from: '/kareedouw-krom-street',
      to: '/branch-office/kareedouw-church-street'
    },
    {
      from: '/heidelberg',
      to: '/branch-office/heidelberg-graham-street-financial-planning'
    },
    {
      from: '/bloemhof-resident-street',
      to: '/branch-office/bloemhof-resident-street'
    },
    {
      from: '/malta',
      to: '/branch-office/malta-kalkara'
    },
    {
      from: '/bethlehem-mullerstraat-oos-financial-planning-eduard-marais',
      to: '/branch-office/bethlehem-mullerstraat-oos-financial-planning-eduard-marais'
    },
    {
      from: '/bethlehem-president-boshoff-street-financial-planning-danie-du-plooy',
      to: '/branch-office/bethlehem-president-boshoff-street-financial-planning-danie-du-plooy'
    },
    {
      from: '/tiaanvangeest',
      to: '/branch-office/bethlehem-34-president-boshoff-street-short-term-tiaan-van-geest'
    },
    {
      from: '/lynnwood',
      to: '/branch-office/die-wilgers-lynnwood-road'
    },
    {
      from: '/midlandshealthcare2',
      to: '/branch-office/midlands-employee-benefits'
    },
    {
      from: '/roodepoort-hendrik-boom-street-healthcare',
      to: '/branch-office/roodepoort-hendrik-boom-street-healthcare'
    },
    {
      from: '/fochville-market-street',
      to: '/branch-office/fochville-market-street'
    },
    {
      from: '/multimanagement',
      to: '/branch-office/the-edge-psg-multi-management'
    },
    {
      from: '/willowbridgecarlcronjedriveshortterm',
      to: '/branch-office/willowbridge-carl-cronje-drive-short-term-werner-prinsloo'
    },
    {
      from: '/lichtenburg-nelson-mandela-road-short-term',
      to: '/branch-office/lichtenburg-nelson-mandela-road-short-term'
    },
    {
      from: '/centurion-short-term',
      to: '/branch-office/centurion-short-term'
    },
    {
      from: '/roodepoort-constantia-office-park-healthcare-mannie-michalopoulos',
      to: '/branch-office/roodepoort-constantia-office-park-healthcare-mannie-michalopoulos'
    },
    {
      from: '/olympus',
      to: '/branch-office/olympus-haymeadow-crescent-stockbroking-and-financial-planning'
    },
    {
      from: '/carindaziegler',
      to: '/branch-office/kimberley-urban-corner-short-term-carinda-ziegler'
    },
    {
      from: '/kimberley-jan-van-zyl-street-financial-planning-gerhard-buys',
      to: '/branch-office/kimberley-jan-van-zyl-street-financial-planning-gerhard-buys'
    },
    {
      from: '/tygerwaterfrontimperialterraces',
      to: '/branch-office/tygerwaterfront-imperial-terraces'
    },
    {
      from: '/upington-anton-lubowski-street-financial-planning',
      to: '/branch-office/upington-anton-lubowski-street-financial-planning'
    },
    {
      from: '/ladismith-south-street',
      to: '/branch-office/ladismith-south-street'
    },
    {
      from: '/phalaborwa-tambotie-street',
      to: '/branch-office/phalaborwa-tambotie-street'
    },
    {
      from: '/westernnationalcapetown',
      to: '/branch-office/tygerfalls-the-edge-western'
    },
    {
      from: '/westernnationalgauteng',
      to: '/branch-office/western-calliandra-street'
    },
    {
      from: '/westernwindhoek',
      to: '/branch-office/western-windhoek'
    },
    {
      from: '/bothaville',
      to: '/branch-office/bothaville-president-street'
    },
    {
      from: '/psg-wealth-northcliff',
      to: '/branch-office/psg-wealth-northcliff'
    },
    {
      from: '/modimolle-thabo-mbeki-road-98-financial-planning-danie-human',
      to: '/branch-office/modimolle-thabo-mbeki-road-98-financial-planning-danie-human'
    },
    {
      from: '/wellington-rose-street-short-term',
      to: '/branch-office/wellington-rose-street-short-term'
    },
    {
      from: '/brooklyn-justice-mahomed-street',
      to: '/branch-office/brooklyn-justice-mahomed-street'
    },
    {
      from: '/richards-bay-pioneer-road',
      to: '/branch-office/richards-bay-pioneer-road'
    },
    {
      from: '/psgstadminunderwritingdistribution',
      to: '/branch-office/psg-st-admin-underwriting-distribution-'
    },
    {
      from: '/polokwanelibrashortterm',
      to: '/branch-office/polokwane-libra-avenue'
    },
    {
      from: '/faerie-glen-stockbroking-and-financial-planning',
      to: '/branch-office/faerie-glen-stockbroking-and-financial-planning'
    },
    {
      from: '/hermanus-dirkie-uys-street-richard-joubert',
      to: '/branch-office/hermanus-dirkie-uys-street-richard-joubert'
    },
    {
      from: '/psgwealthgroupservicesptyltd',
      to: '/branch-office/psg-wealth-group-services-pty-ltd'
    },
    {
      from: '/krugersdorp-viljoen-street',
      to: '/branch-office/krugersdorp-viljoen-street'
    },
    {
      from: '/magalieskruin-braam-pretorius-street',
      to: '/branch-office/magalieskruin-braam-pretorius-street'
    },
    {
      from: '/paarl-main-road-211-stockbroking',
      to: '/branch-office/paarl-main-road-211-stockbroking'
    },
    {
      from: '/oudtshoorn-st-john-street',
      to: '/branch-office/oudtshoorn-st-john-street'
    },
    {
      from: '/jan-kempdorp-frans-lubbe-street',
      to: '/branch-office/jan-kempdorp-frans-lubbe-street'
    },
    {
      from: '/psgstadminunderwritingdistributioncapetown',
      to: '/branch-office/psg-st-admin-underwriting-distribution-cape-town-'
    },
    {
      from: '/roodepoorthealthcareol',
      to: '/branch-office/ruimsig-healthcare-louise-olivier'
    },
    {
      from: '/roodepoortwillowbrooke',
      to: '/branch-office/ruimsig-financial-planning'
    },
    {
      from: '/old-oak',
      to: '/branch-office/old-oak'
    },
    {
      from: '/langebaan-oostewal-street-financial-planning',
      to: '/branch-office/langebaan-oostewal-street-financial-planning'
    },
    {
      from: '/uvongo-marine-drive',
      to: '/branch-office/uvongo-marine-drive'
    },
    {
      from: '/the-edge-wealth-product-specialists',
      to: '/branch-office/the-edge-wealth-product-specialists'
    },
    {
      from: '/malmesbury-piet-retief-street',
      to: '/branch-office/malmesbury-piet-retief-street'
    },
    {
      from: '/centurion-midstream',
      to: '/branch-office/centurion-midstream'
    },
    {
      from: '/westernoudtshoorn',
      to: '/branch-office/western-oudtshoorn'
    },
    {
      from: '/postmasburghoofstraatas',
      to: '/branch-office/postmasburg-randjie-street'
    },
    {
      from: '/caledon-prins-albert-street',
      to: '/branch-office/caledon-prins-albert-street'
    },
    {
      from: '/plattekloofmg',
      to: '/branch-office/durbanville-oxford-street-short-term-m-grobler'
    },
    {
      from: '/potchefstroom1',
      to: '/branch-office/potchefstroom-3-palmiet-street-stockbroking'
    },
    {
      from: '/roodepoort-constantia-office-park-marius-burger',
      to: '/branch-office/roodepoort-constantia-office-park-marius-burger'
    },
    {
      from: '/vredenburg-heuningklip-street-financial-planning',
      to: '/branch-office/vredenburg-heuningklip-street-financial-planning'
    },
    {
      from: '/villiersdorpshortterm',
      to: '/branch-office/villiersdorp-main-road-short-term'
    },
    {
      from: '/winelands',
      to: '/branch-office/winelands'
    },
    {
      from: '/centurion-die-hoewes-short-term-mariza-fuller-petersen',
      to: '/branch-office/centurion-die-hoewes-short-term-mariza-fuller-petersen'
    },
    {
      from: '/kempton-park-monument-road-financial-planning',
      to: '/branch-office/kempton-park-monument-road-financial-planning'
    },
    {
      from: '/walvis-bay',
      to: '/branch-office/walvis-bay'
    },
    {
      from: '/centurion-lyttelton-short-term-traven-moodley',
      to: '/branch-office/centurion-lyttelton-short-term-traven-moodley'
    },
    {
      from: '/bryanstoninvest',
      to: '/branch-office/waterfall-invest'
    },
    {
      from: '/bloemfontein-pretty-gardens-short-term-ryno-pretorius',
      to: '/branch-office/bloemfontein-pretty-gardens-short-term-ryno-pretorius'
    },
    {
      from: '/potchefstroompalmiet',
      to: '/branch-office/potchefstroom-3-palmiet-street-financial-planning-niekie-van-der-walt'
    },
    {
      from: '/kloof-old-main-road-short-term',
      to: '/branch-office/umhlanga-short-term'
    },
    {
      from: '/somerset-west-mall-ring-road-short-term',
      to: '/branch-office/somerset-west-mall-ring-road-short-term'
    },
    {
      from: '/somerset-west-st-james-street-financial-planning',
      to: '/branch-office/somerset-west-st-james-street-financial-planning'
    },
    {
      from: '/grahamstown-oatlands-road-short-term',
      to: '/branch-office/grahamstown-oatlands-road-short-term'
    },
    {
      from: '/durbanville-durban-road-financial-planning-hendro-brand',
      to: '/branch-office/durbanville-durban-road-financial-planning-hendro-brand'
    },
    {
      from: '/bartlett-elizabeth-road-willie-mostert',
      to: '/branch-office/boksburg-newton-road-willie-mostert'
    },
    {
      from: '/bergrivier-boulevard',
      to: '/branch-office/bergrivier-boulevard'
    },
    {
      from: '/houghtoncentralstreetstockbrokingnicklouw',
      to: '/branch-office/firestation-saxonwold-stockbroking-nick-louw'
    },
    {
      from: '/bartlett-elizabeth-road-jp-roos',
      to: '/branch-office/boksburg-newton-road-jp-roos'
    },
    {
      from: '/capetownwaltersisuluavenuebb',
      to: '/branch-office/cape-town-strand-street-bruce-barclay'
    },
    {
      from: '/somerset-west-mall-ring-road-stockbroking-gerbrand-willemse',
      to: '/branch-office/somerset-west-mall-ring-road-stockbroking-gerbrand-willemse'
    },
    {
      from: '/william-nicol-drive-sandton',
      to: '/branch-office/william-nicol-drive-sandton'
    },
    {
      from: '/ladysmith-hunter-road',
      to: '/branch-office/ladysmith-hunter-road'
    },
    {
      from: '/durbanville-oxford-street',
      to: '/branch-office/durbanville-oxford-street'
    },
    {
      from: '/wealthclientservices',
      to: '/branch-office/waterfall-wealth-client-services'
    },
    {
      from: '/middelburg-walter-sisulu-drive-short-term-luhan-vd-linde',
      to: '/branch-office/middelburg-wes-street-short-term-luhan-vd-linde'
    },
    {
      from: '/wealthsales',
      to: '/branch-office/waterfall-wealth-sales'
    },
    {
      from: '/bethal-clerq-street',
      to: '/branch-office/bethal-clerq-street'
    },
    {
      from: '/farrarmere',
      to: '/branch-office/farrarmere'
    },
    {
      from: '/paarllaconcordekwvbuildingcraigbester',
      to: '/branch-office/paarl-la-concorde-kwv-building-craig-bester'
    },
    {
      from: '/hyde-park-financial-planning',
      to: '/branch-office/hyde-park-financial-planning'
    },
    {
      from: '/pretoria-brooklyn-road-louise-venter',
      to: '/branch-office/pretoria-brooklyn-road-louise-venter'
    },
    {
      from: '/plattekloof-roy-de-vries-drive-short-term-b-oosthuizen',
      to: '/branch-office/plattekloof-roy-de-vries-drive-short-term-b-oosthuizen'
    },
    {
      from: '/tyger-waterfront-raul-jorge',
      to: '/branch-office/tyger-waterfront-raul-jorge'
    },
    {
      from: '/hyde-park-securities',
      to: '/branch-office/hyde-park-securities'
    },
    {
      from: '/humansdorp-kemp-street-short-term-bm',
      to: '/branch-office/jeffreys-bay-fountains-business-park-bm'
    },
    {
      from: '/kloof-old-main-road-stockbroking',
      to: '/branch-office/kloof-old-main-road-stockbroking'
    },
    {
      from: '/paarl-main-road-211-financial-planning-lukas-scheepers',
      to: '/branch-office/paarl-main-road-211-financial-planning-lukas-scheepers-&-eduard-mostert'
    },
    {
      from: '/paarl-main-road-211-financial-planning-danie-cronje',
      to: '/branch-office/paarl-main-road-211-financial-planning-danie-cronje'
    },
    {
      from: '/constantia-main-road-fiduciary-julian-boshoff',
      to: '/branch-office/constantia-main-road-fiduciary-julian-boshoff'
    },
    {
      from: '/bergrivierboulevardshortterm3',
      to: '/branch-office/bergrivier-boulevard'
    },
    {
      from: '/northcliff-financial-planning-steven-bayne',
      to: '/branch-office/northcliff-financial-planning-steven-bayne'
    },
    {
      from: '/middelburg-samora-machel-street',
      to: '/branch-office/middelburg-samora-machel-street'
    },
    {
      from: '/mosselbaydiaz',
      to: '/branch-office/mossel-bay-diaz'
    },
    {
      from: '/middelburgmpumalangashortterm',
      to: '/branch-office/middelburg-mpumalanga-short-term'
    },
    {
      from: '/williamnicoldrivesandtoniantaylor',
      to: '/branch-office/william-nicol-drive-sandton-ian-taylor'
    },
    {
      from: '/douglascampbellstreetfinancialplanning',
      to: '/branch-office/douglas-campbell-street-financial-planning'
    },
    {
      from: '/kimberleyjanvanzylstreetfinancialplanning',
      to: '/branch-office/kimberley-jan-van-zyl-street-financial-planning'
    },
    {
      from: '/northclifffinancialplanningjeanstruweg',
      to: '/branch-office/northcliff-financial-planning-jean-struweg'
    },
    {
      from: '/swellendamvoortrekstreet65',
      to: '/branch-office/swellendam-voortrek-street-65'
    },
    {
      from: '/bergrivierboulevardshortterm2',
      to: '/branch-office/bergrivier-boulevard'
    },
    {
      from: '/vanderbijlparkrabiestreetshortterm',
      to: '/branch-office/vanderbijlpark-rabie-street-short-term'
    },
    {
      from: '/uitenhagemoselroadfinancialplanningjaninecandido',
      to: '/branch-office/uitenhage-mosel-road-financial-planning-janine-candido'
    },
    {
      from: '/bloemfonteinlangenhovenparklouise',
      to: '/branch-office/bloemfontein-donald-murray-ave-insure-louise-pretorius'
    },
    {
      from: '/sandtonwealthplanningjuliepeter',
      to: '/branch-office/sandton-wealth-planning-julie-peter'
    },
    {
      from: '/centurionvonwillichavenuefinancialplanning',
      to: '/branch-office/centurion-von-willich-avenue-financial-planning'
    },
    {
      from: '/rubensteindrivenatashamasters',
      to: '/branch-office/rubenstein-drive-natasha-masters'
    },
    {
      from: '/westernwalvisbay',
      to: '/branch-office/western-walvis-bay'
    },
    {
      from: '/sandtonwealthmanagement',
      to: '/branch-office/sandton-wealth-management'
    },
    {
      from: '/rubensteindrivereneeaspinall',
      to: '/branch-office/rubenstein-drive-renee-aspinall'
    },
    {
      from: '/langebaanoostewalstreetheindevilliers',
      to: '/branch-office/langebaan-oostewal-street-hein-de-villiers'
    },
    {
      from: '/kimberleydutoitspanroad',
      to: '/branch-office/kimberley-du-toitspan-road'
    },
    {
      from: '/umhlangarocksgranadasquarefinancialplanning',
      to: '/branch-office/umhlanga-rocks-granada-square-financial-planning'
    },
    {
      from: '/hydeparkfinancialplanningarnaudmalherbe',
      to: '/branch-office/sandton-grayston-wealth-arnaud-malherbe'
    },
    {
      from: '/bloemhofpersonallines',
      to: '/branch-office/bloemhof-short-term'
    },
    {
      from: '/sandton-illovopoint-melville-rd-short-term',
      to: '/branch-office/sandton-illovo-point-melville-rd-short-term'
    },
    {
      from: '/northcliffpartnership',
      to: '/branch-office/northcliff-partnership'
    },
    {
      from: '/northclifffinancialplanningansiedebeer',
      to: '/branch-office/northcliff-financial-planning-ansie-de-beer'
    },
    {
      from: '/northclifffinancialplanninglouisdevilliers',
      to: '/branch-office/northcliff-financial-planning-louis-de-villiers'
    },
    {
      from: '/northclifffinancialplanningkimrunzler',
      to: '/branch-office/northcliff-financial-planning-kim-runzler'
    },
    {
      from: '/northclifffinancialplanninglodijordaan',
      to: '/branch-office/northcliff-financial-planning-lodi-jordaan'
    },
    {
      from: '/northclifffinancialplanningnealgoldwyer',
      to: '/branch-office/northcliff-financial-planning-neal-goldwyer'
    },
    {
      from: '/tokaivansrdshortterm',
      to: '/branch-office/tokai-vans-rd-short-term'
    },
    {
      from: '/northcliff-financial-planning',
      to: '/branch-office/northcliff-financial-planning-jp-fourie'
    },
    {
      from: '/newcastlegemsbokstreetfinancialplanningmarnussteenkamp',
      to: '/branch-office/newcastle-gemsbok-street-financial-planning-marnus-steenkamp'
    },
    {
      from: '/rubensteindrivepierreloots',
      to: '/branch-office/rubenstein-drive-pierre-loots'
    },
    {
      from: '/bredasdorpshortterm',
      to: '/branch-office/bredasdorp-short-term'
    },
    {
      from: '/mosselbaychurchstreetjohannswanepoel',
      to: '/branch-office/mossel-bay-meyer-street-johann-swanepoel'
    },
    {
      from: '/ballitosimbithifinancialplanning',
      to: '/branch-office/ballito-simbithi-office-park-financial-planning'
    },
    {
      from: '/ballitosimbithitrust',
      to: '/branch-office/ballito-simbithi-office-park-trust'
    },
    {
      from: '/ballitosimbithishortterm',
      to: '/branch-office/ballito-simbithi-office-park-short-term'
    },
    {
      from: '/sedgefieldmosaicvillage',
      to: '/branch-office/sedgefield-mosaic-village'
    },
    {
      from: '/psg-wealth-rosebank',
      to: '/branch-office/psg-wealth-rosebank'
    },
    {
      from: '/nelspruitmostertstreet',
      to: '/branch-office/nelspruit-mostert-street'
    },
    {
      from: '/umhlangaholwoodcrescentbrandfinhousefiduciaryservices',
      to: '/branch-office/umhlanga-holwood-crescent-brandfin-house-fiduciary-services'
    },
    {
      from: '/stellenboschdorpstreetfrancoiskotze',
      to: '/branch-office/stellenbosch-dorp-street-zander-twine'
    },
    {
      from: '/westavenueshorttermlouisadelange',
      to: '/branch-office/centurion-west-avenue-short-term:-louisa-de-lange'
    },
    {
      from: '/empangeninormantedderlanehealthcare',
      to: '/branch-office/empangeni-norman-tedder-lane-healthcare'
    },
    {
      from: '/eastrandatlasroadshorttermrietteventer',
      to: '/branch-office/east-rand-atlas-road-short-term-riette-venter'
    },
    {
      from: '/boksburgtrichardsroadshorttermsoniavandermerwe',
      to: '/branch-office/boksburg-trichard-road-short-term-sonia-van-der-merwe'
    },
    {
      from: '/sandtonwilliamnicolshorttermaubreykok',
      to: '/branch-office/northcliff-short-term-aubrey-kok'
    },
    {
      from: '/sandtonwilliamnicolshorttermheinterblanche',
      to: '/branch-office/northcliff-short-term-hein-terblanche'
    },
    {
      from: '/sandtonwilliamnicolshorttermyolandeviljoen1',
      to: '/branch-office/sandton-short-term-yolande-viljoen'
    },
    {
      from: '/sandtonwilliamnicolshorttermderekhay',
      to: '/branch-office/east-rand-atlas-road-short-term-derek-hay'
    },
    {
      from: '/sandtonwilliamnicolshorttermaletpelser',
      to: '/branch-office/hyde-park-short-term-alet-pelser'
    },
    {
      from: '/vanderbijlparkrabiestreetshorttermjoelindadimond',
      to: '/branch-office/vanderbijlpark-rabie-street-short-term-joelinda-dimond'
    },
    {
      from: '/vereenigingrhodesavenueshorttermmaritzasteyn',
      to: '/branch-office/vereeniging-rhodes-avenue-short-term-maritza-steyn'
    },
    {
      from: '/richardsbaypartridgeplaceshorttermjackiecafun',
      to: '/branch-office/richardsbay-partridge-place-short-term-jackie-cader'
    },
    {
      from: '/newcastlegemsbokstreetno7shorttermelizabethbothma',
      to: '/branch-office/newcastle-74-erica-drive-short-term-elizabeth-bothma'
    },
    {
      from: '/umhlangaholwoodcrescentbrandfinhouseshorttermmarkcombrink',
      to: '/branch-office/mount-edgecombe-nkwazi-office-park-short-term-mark-combrink'
    },
    {
      from: '/umhlangaholwoodcrescentbrandfinhouseshorttermcharmaineharichunder',
      to: '/branch-office/mount-edgecombe-kingfisher-office-park-short-term'
    },
    {
      from: '/umhlangaholwoodcrescentbrandfinhouseshorttermcookienaidoo',
      to: '/branch-office/mount-edgecombe-nkwazi-office-park-short-term-cookie-naidoo'
    },
    {
      from: '/stellenbosch-techno-park',
      to: '/branch-office/stellenbosch-techno-park'
    },
    {
      from: '/aliwalnorthbankstreetshorttermrozaanvanderwalt',
      to: '/branch-office/aliwal-north-bank-street-short-term-rozaan-van-der-walt'
    },
    {
      from: '/durbanvilleoxfordstreetshorttermhannestruter',
      to: '/branch-office/durbanville-oxford-street-short-term-hannes-truter'
    },
    {
      from: '/durbanville-oxford-street-short-term-samantha-van-zyl',
      to: '/branch-office/durbanville-oxford-street-short-term-samantha-van-zyl'
    },
    {
      from: '/durbanvilleoxfordstreetshorttermjacquesvisser',
      to: '/branch-office/durbanville-oxford-street-short-term-jacques-visser'
    },
    {
      from: '/eastlondonsansomroadshorttermtalitakama',
      to: '/branch-office/mthatha-madeira-street-short-term-talita-kama'
    },
    {
      from: '/rosenparkpasitastreetshorttermleonieerasmus',
      to: '/branch-office/durbanville-oxford-street-st-leonie-erasmus'
    },
    {
      from: '/georgeyorkstreetshorttermshereneburger',
      to: '/branch-office/george-york-street-short-term-sherene-burger'
    },
    {
      from: '/humansdorpchurchstreetshorttermdeondupisanie1',
      to: '/branch-office/jeffreys-bay-oosterland-street-short-term-deon-du-pisanie'
    },
    {
      from: '/montevistabarnardstreetsttoniehaarhoff',
      to: '/branch-office/monte-vista-barnard-street-st-tonie-haarhoff'
    },
    {
      from: '/montevistabarnardstreetstmariusvanwillingh',
      to: '/branch-office/monte-vista-barnard-street-st-marius-van-willingh'
    },
    {
      from: '/cradockspriggstreetshorttermjuanitabotha',
      to: '/branch-office/cradock-sprigg-street-short-term-juanita-botha'
    },
    {
      from: '/oudtshoornstjohnstreetshorttermemilecalitz',
      to: '/branch-office/oudtshoorn-st-john-street-short-term-emile-calitz'
    },
    {
      from: '/paarlmainroad42bshorttermluzanneloubser',
      to: '/branch-office/paarl-cecilia-street-short-term-luzanne-loubser'
    },
    {
      from: '/plattekloofkiepersolcloseshorttermjohanconradie',
      to: '/branch-office/durbanville-oxford-street-short-term-johan-conradie'
    },
    {
      from: '/plattekloofkiepersolcloseshorttermstephanpretorius',
      to: '/branch-office/durbanville-oxford-street-short-term-stephan-pretorius'
    },
    {
      from: '/portelizabethmainroadwalmerdianagummow',
      to: '/branch-office/port-elizabeth-main-road-walmer-diana-gummow'
    },
    {
      from: '/portelizabethmainroadwalmerbriankemsley',
      to: '/branch-office/port-elizabeth-main-road-walmer-brian-kemsley'
    },
    {
      from: '/lowveld-stockbroking-financial-planning',
      to: '/branch-office/lowveld-stockbroking-&-financial-planning'
    },
    {
      from: '/polokwaneisminiparkfiduciaryservices',
      to: '/branch-office/polokwane-ismini-park-fiduciary-services'
    },
    {
      from: '/polokwane-ismini-park-gerhard-mare',
      to: '/branch-office/polokwane-ismini-park-gerhard-mare'
    },
    {
      from: '/polokwaneisminiparkhannesmuller',
      to: '/branch-office/polokwane-ismini-park-hannes-muller'
    },
    {
      from: '/polokwaneisminiparkjohanvanravesteyn',
      to: '/branch-office/polokwane-ismini-park-johan-van-ravesteyn'
    },
    {
      from: '/bloemfonteinlangenhovenparkshorttermgarthzandberg',
      to: '/branch-office/bloemfontein-donald-murray-ave-insure-garth-zandberg'
    },
    {
      from: '/buffelspark48buffeldoornstreetshorttermjuliuslabuschagne',
      to: '/branch-office/buffelspark-48-buffeldoorn-street-short-term-julius-labuschagne'
    },
    {
      from: '/klerksdorpunit8buffelsparkstreetflamwoodshorttermingridoliver',
      to: '/branch-office/klerksdorp-unit-9a-buffelspark-48-buffeldoorn-street-flamwood-short-term-ingrid-olivier'
    },
    {
      from: '/lichtenburgnelsonmandelaroadshorttermceciliaesterhuizen',
      to: '/branch-office/lichtenburg-nelson-mandela-road-short-term-cecilia-esterhuizen'
    },
    {
      from: '/lichtenburgnelsonmandelaroadshorttermcorrieludick',
      to: '/branch-office/lichtenburg-nelson-mandela-road-short-term-corrie-ludick'
    },
    {
      from: '/lichtenburgnelsonmandelaroadshorttermaudreyscheepers',
      to: '/branch-office/lichtenburg-nelson-mandela-road-short-term-audrey-scheepers'
    },
    {
      from: '/kurumanmainroadshorttermnataliekotze',
      to: '/branch-office/kuruman-main-road-short-term-natalie-kotze'
    },
    {
      from: '/kimberleymemorialroadshorttermbraamvantonder',
      to: '/branch-office/kimberley-memorial-road-short-term-braam-van-tonder'
    },
    {
      from: '/bloemfontein36waterbergavenueshorttermisabeljankowitz',
      to: '/branch-office/bloemfontein-36-waterberg-avenue-short-term-isabel-jankowitz'
    },
    {
      from: '/bloemfontein36waterbergavenueshorttermcherylmoolman',
      to: '/branch-office/bloemfontein-36-waterberg-avenue-short-term-cheryl-moolman'
    },
    {
      from: '/schweizerrenekerenekestreetshorttermchristenevantonder',
      to: '/branch-office/schweizer-reneke-schweizer-street-short-term-christene-van-tonder'
    },
    {
      from: '/psgdeankerrustenburgshorttermwerneralbrecht',
      to: '/branch-office/rustenburg-de-anker-59-a-von-wielligh-street-short-term-werner-albrecht'
    },
    {
      from: '/rustenburgdeanker59vonwiellighstreetshorttermhermannschulenburg',
      to: '/branch-office/rustenburg-de-anker-59-a-von-wielligh-street-short-term-hermann-schulenburg'
    },
    {
      from: '/upingtonantonlubowskistreetshorttermdewethenn',
      to: '/branch-office/upington-anton-lubowski-street-short-term-de-wet-henn'
    },
    {
      from: '/bethalclerqstreetshorttermmarionettegroenewald',
      to: '/branch-office/bethal-kleynhans-street-short-term'
    },
    {
      from: '/faerieglenshorttermthabomojahi',
      to: '/branch-office/hatfield-grosvenor-street-thabo-mojahi'
    },
    {
      from: '/faerieglenshorttermleonienel',
      to: '/branch-office/faerie-glen-short-term-leonie-nel'
    },
    {
      from: '/middelburgwesstreetshorttermhannesvanderlinde',
      to: '/branch-office/middelburg-wes-street-short-term-hannes-van-der-linde'
    },
    {
      from: '/olympus-midas-avenue-short-term-markus-fourie',
      to: '/branch-office/olympus-midas-avenue-short-term-markus-fourie'
    },
    {
      from: '/nelspruitvandermerwestreetshorttermgysstrydom',
      to: '/branch-office/nelspruit-46-mostert-street-short-term-gys-strydom'
    },
    {
      from: '/nelspruitvandermerwestreetshorttermfrancivanniekerk',
      to: '/branch-office/nelspruit-46-mostert-street-short-term-franci-van-niekerk'
    },
    {
      from: '/ermelochurchstreetshorttermmartinduncan',
      to: '/branch-office/ermelo-church-street-short-term-martin-duncan'
    },
    {
      from: '/groblersdalvanriebeekstreetshorttermmerciameiring',
      to: '/branch-office/groblersdal-van-riebeek-street-short-term-mercia-meiring'
    },
    {
      from: '/witbankfinancialsquarebuildingshorttermantoinetteuys',
      to: '/branch-office/witbank-financial-square-building-short-term-piet-van-zyl'
    },
    {
      from: '/brooklyn-insure-elmarie-strauss',
      to: '/branch-office/brooklyn-insure-elmarie-strauss'
    },
    {
      from: '/priscillabezuidenhoutbrooklynbridge570',
      to: '/branch-office/centurion-midstream-insure-priscilla-bezuidenhout'
    },
    {
      from: '/witbankwoltemadestreetshorttermpietvanzyl',
      to: '/branch-office/witbank-woltemade-street-short-term-antoinette-uys'
    },
    {
      from: '/polokwaneisministreet7shorttermtresiaeloff',
      to: '/branch-office/polokwane-ismini-street-26-short-term-tresia-eloff'
    },
    {
      from: '/pretoriasouthdownsjohnvorsterdriveshorttermronellevanaswegen',
      to: '/branch-office/centurion-midstream-insure-ronelle-van-aswegen'
    },
    {
      from: '/tzaneenagathastreetshortterminavanderschyff',
      to: '/branch-office/tzaneen-agatha-street-short-term-ina-van-der-schyff'
    },
    {
      from: '/witbankvlaklaagteshorttermtezagates',
      to: '/branch-office/witbank-vlaklaagte-short-term-teza-gates'
    },
    {
      from: '/hartbeespoortdambeethovenstreetshorttermrynopienaar',
      to: '/branch-office/hartbeespoort-beethoven-street-ryno-pienaar-short-term'
    },
    {
      from: '/sinovillezambezilaneshorttermcorneljansevanvuuren',
      to: '/branch-office/sinoville-zambezi-drive'
    },
    {
      from: '/ballitosimbithiofficeparkfinancialplanningshaunlatter',
      to: '/branch-office/ballito-simbithi-office-park-financial-planning-shaun-latter'
    },
    {
      from: '/northernfiduciaryserviceskoljanderavenue1',
      to: '/branch-office/northern-fiduciary-services-koljander-avenue'
    },
    {
      from: '/potchefstroomfickstreetfinancialplanningreinhardtpretorius',
      to: '/branch-office/potchefstroom-fick-street-financial-planning-reinhardt-pretorius'
    },
    {
      from: '/stilbaaifinancialplanning',
      to: '/branch-office/stilbaai-financial-planning'
    },
    {
      from: '/pretoriasouthdownsjohnvorsterdriveshorttermleonsenekal',
      to: '/branch-office/centurion-midstream-insure-leon-senekal'
    },
    {
      from: '/vryheid-hlobane-str-short-term',
      to: '/branch-office/vryheid-hlobane-str-short-term'
    },
    {
      from: '/empangeninormantedderlanefinancialplanningraymonddavidovics',
      to: '/branch-office/empangeni-norman-tedder-lane-financial-planning-raymond-davidovics'
    },
    {
      from: '/oldoakrichusnel',
      to: '/branch-office/old-oak-richus-nel'
    },
    {
      from: '/paarl-cecilia-square-stockbroking',
      to: '/branch-office/paarl-cecilia-square-stockbroking'
    },
    {
      from: '/firestation-saxonwold',
      to: '/branch-office/firestation-saxonwold'
    },
    {
      from: '/bloemfonteinkolperdstreetshorttermkobussteyn',
      to: '/branch-office/bloemfontein-kolperd-street-short-term-kobus-steyn'
    },
    {
      from: '/hartswaterdfmalanstreetshorttermmariethavanvuuren',
      to: '/branch-office/hartswater-df-malan-street-short-term-marietha-van-vuuren'
    },
    {
      from: '/kimberleyjanvanzylstreetshorttermalidalamb',
      to: '/branch-office/kimberley-jan-van-zyl-street-short-term-alida-lamb'
    },
    {
      from: '/kimberleymemorialroadshorttermmarielouisegroenewald',
      to: '/branch-office/kimberley-memorial-road-short-term-marie-louise-groenewald'
    },
    {
      from: '/kimberleymemorialroadshorttermisabeljeppe',
      to: '/branch-office/kimberley-long-street-short-term-isabel-jeppe'
    },
    {
      from: '/potchefstroomfickstreetshorttermcharldaubney',
      to: '/branch-office/rustenburg-de-anker-59-a-von-wielligh-street-short-term-charl-daubney'
    },
    {
      from: '/potchefstroomfickstreetshorttermdamanmoos',
      to: '/branch-office/potchefstroom-fick-street-short-term-daman-moos'
    },
    {
      from: '/psgdeankerrustenburgshorttermpieterengelbrecht',
      to: '/branch-office/rustenburg-de-anker-59-a-von-wielligh-street-short-term-pieter-engelbrecht'
    },
    {
      from: '/rustenburgdeanker59avonwiellighstreetshorttermantoinettevonmollendorf',
      to: '/branch-office/rustenburg-de-anker-59-a-von-wielligh-street-short-term-antoinette-von-mollendorf'
    },
    {
      from: '/welkomarraratroadshorttermyolandarelling',
      to: '/branch-office/welkom-arrarat-road-short-term-yolanda-relling'
    },
    {
      from: '/justicechuenebrooklynbridge570',
      to: '/branch-office/hatfield-grosvenor-street-justice-chuene'
    },
    {
      from: '/hydeparkshorttermetienneviljoen',
      to: '/branch-office/hyde-park-short-term-etienne-viljoen'
    },
    {
      from: '/eastrandatlasroadshorttermesmarieveldtman',
      to: '/branch-office/east-rand-atlas-road-short-term-esmarie-veldtman'
    },
    {
      from: '/vanderbijlparkshorttermliezelschillings',
      to: '/branch-office/vanderbijlpark-short-term-liezel-schillings'
    },
    {
      from: '/faerieglenshorttermevanlouwrens',
      to: '/branch-office/faerie-glen-short-term-evan-louwrens'
    },
    {
      from: '/faerieglenshorttermheinrichaspeling',
      to: '/branch-office/faerie-glen-short-term-heinrich-aspeling'
    },
    {
      from: '/witbankfinancialsquarebuildingshorttermbernadinedreyer',
      to: '/branch-office/witbank-financial-square-building-short-term-bernadine-dreyer'
    },
    {
      from: '/secundaboshoffstreetshortterm',
      to: '/branch-office/secunda-kiewiet-street-short-term-charmaine-brits'
    },
    {
      from: '/butterworthshorttermbongincobo',
      to: '/branch-office/butterworth-short-term-bongi-ncobo'
    },
    {
      from: '/durbanvilleoxfordstreetshorttermelanafrankematthecka',
      to: '/branch-office/durbanville-oxford-street-short-term-elana-franke-matthecka'
    },
    {
      from: '/durbanvilleoxfordstreetshorttermchristopherkeogh',
      to: '/branch-office/durbanville-oxford-street-short-term-christopher-keogh'
    },
    {
      from: '/colesbergcharlcillierstreet',
      to: '/branch-office/colesberg-charl-cillier-street'
    },
    {
      from: '/claremontpearceroadshorttermfadilahbardien',
      to: '/branch-office/claremont-pearce-road-short-term-fadilah-bardien'
    },
    {
      from: '/claremontpearceroadshorttermcarolmay',
      to: '/branch-office/claremont-pearce-road-short-term-carol-may'
    },
    {
      from: '/eastlondonstewartstreetshorttermcarelduplooy',
      to: '/branch-office/east-london-stewart-street-short-term-carel-du-plooy'
    },
    {
      from: '/kingwilliamstownsmithstreetshorttermthandisigaji',
      to: '/branch-office/king-williams-town-smith-street-short-term-thandi-sigaji'
    },
    {
      from: '/mthathamadeirastreetshorttermnobomjonga',
      to: '/branch-office/mthatha-madeira-street-short-term-nobom-jonga'
    },
    {
      from: '/mthathamadeirastreetshorttermprudymiso',
      to: '/branch-office/mthatha-madeira-street-short-term-prudy-miso'
    },
    {
      from: '/montevistabarnardstreetstantonhaarhoff',
      to: '/branch-office/monte-vista-barnard-street-st-anton-haarhoff'
    },
    {
      from: '/montevistabarnardstreetstannamarieolivier',
      to: '/branch-office/monte-vista-barnard-street-st-anna-marie-olivier'
    },
    {
      from: '/edgemeadsouthdaleroadsttrevorprins',
      to: '/branch-office/edgemead-southdale-road-st-trevor-prins'
    },
    {
      from: '/montevistabarnardstreetstshaneking',
      to: '/branch-office/monte-vista-barnard-street-st-shane-king'
    },
    {
      from: '/oudtshoornstjohnstreetmarinaboshoff',
      to: '/branch-office/oudtshoorn-st-john-street-marina-boshoff'
    },
    {
      from: '/portelizabethmainroadwalmerdavidrensburg',
      to: '/branch-office/port-elizabeth-main-road-walmer-david-rensburg'
    },
    {
      from: '/portelizabethmainroadwalmermeudenesteyn',
      to: '/branch-office/port-elizabeth-main-road-walmer-meudene-steyn'
    },
    {
      from: '/portelizabethmainroadwalmernikithompson',
      to: '/branch-office/port-elizabeth-main-road-walmer-niki-thompson'
    },
    {
      from: '/portelizabethmainroadwalmercorneventer',
      to: '/branch-office/port-elizabeth-main-road-walmer-corne-venter'
    },
    {
      from: '/durbanvilleoxfordstreetshorttermnitameiring',
      to: '/branch-office/durbanville-oxford-street-short-term-nita-meiring'
    },
    {
      from: '/durbanvilleoxfordstreetshorttermdalenevolschenk',
      to: '/branch-office/durbanville-oxford-street-short-term-dalene-volschenk'
    },
    {
      from: '/rosenparkpasitastreetshorttermmarelizedavies',
      to: '/branch-office/rosenpark-pasita-street-st-marelize-davies'
    },
    {
      from: '/rosenpark-pasita-street-short-term-wilna-mulder',
      to: '/branch-office/rosenpark-pasita-street-st-wilna-mulder'
    },
    {
      from: '/rosenparkpasitastreetshorttermjeanmaresanetra',
      to: '/branch-office/rosenpark-pasita-street-stjean-mare-sanetra'
    },
    {
      from: '/vredendalvoortrekkerstreetshorttermgideonroux',
      to: '/branch-office/vredendal-voortrekker-street-short-term-gideon-roux'
    },
    {
      from: '/umhlangaholwoodcrescentbrandfinhouseshorttermriaazshaik',
      to: '/branch-office/mount-edgecombe-nkwazi-office-park-short-term-riaaz-shaik'
    },
    {
      from: '/paarlceciliasquarelesylpotgieter',
      to: '/branch-office/paarl-cecilia-square-lesyl-potgieter'
    },
    {
      from: '/verulamrussomstreetshorttermmohammedbuckas',
      to: '/branch-office/verulam-russom-street-short-term-mohammed-buckas'
    },
    {
      from: '/capetownstrandstreetchristait',
      to: '/branch-office/cape-town-strand-street-chris-tait'
    },
    {
      from: '/worcesteradderleystreetfinancialplanning',
      to: '/branch-office/worcester-adderley-street-financial-planning'
    },
    {
      from: '/tzaneenfinancialplanning',
      to: '/branch-office/tzaneen-financial-planning'
    },
    {
      from: '/stilbaai7vanriebeeckstreet',
      to: '/branch-office/stilbaai-fynbos-street-willie-olivier'
    },
    {
      from: '/mosselbayjohanviljoen',
      to: '/branch-office/mossel-bay-church-street-johan-viljoen'
    },
    {
      from: '/sinovillezambesiavenue148',
      to: '/branch-office/sinoville-zambesi-avenue-148'
    },
    {
      from: '/pongolashortterm',
      to: '/branch-office/pongola-short-term'
    },
    {
      from: '/rosenparkpasitastreetshorttermheinrichvanzyl',
      to: '/branch-office/rosenpark-pasita-street-st-heinrich-van-zyl'
    },
    {
      from: '/portelizabeth190caperoadfinancialplanningfaniestrydom',
      to: '/branch-office/port-elizabeth-190-cape-road-financial-planning-fanie-strydom'
    },
    {
      from: '/mokopanepretoriusstreetfransblignaut',
      to: '/branch-office/mokopane-pretorius-street-frans-blignaut'
    },
    {
      from: '/burgersfort',
      to: '/branch-office/burgersfort'
    },
    {
      from: '/uitenhagemoselroadshortterm',
      to: '/branch-office/uitenhage-mosel-road-short-term'
    },
    {
      from: '/parysallemanstreetfinancialplanning',
      to: '/branch-office/parys-alleman-street-financial-planning'
    },
    {
      from: '/parysallemanstreetshorttermkrugerterblanche',
      to: '/branch-office/parys-alleman-street-short-term-kruger-terblanche'
    },
    {
      from: '/paarllaconcordekwvbuildinghandreburger',
      to: '/branch-office/paarl-la-concorde-kwv-building-handre-burger'
    },
    {
      from: '/bethlehemmullerstraatoosfinancialplanningjacocilliers',
      to: '/branch-office/bethlehem-mullerstraat-oos-financial-planning-jaco-cilliers'
    },
    {
      from: '/psgumhlangastockbroking',
      to: '/branch-office/umhlanga-stockbroking'
    },
    {
      from: '/welkomstatewayshorttermannemariburger',
      to: '/branch-office/welkom-stateway-short-term-anne-mari-burger'
    },
    {
      from: '/ladybranddanpienaarstreet',
      to: '/branch-office/ladybrand-dan-pienaar-street'
    },
    {
      from: '/rustenburgkockstreetwernerwenhold',
      to: '/branch-office/rustenburg-kock-street-werner-wenhold'
    },
    {
      from: '/rustenburgkockstreetivanlummis',
      to: '/branch-office/rustenburg-kock-street-ivan-lummis'
    },
    {
      from: '/rustenburgkockstreetfiduciary',
      to: '/branch-office/rustenburg-kock-street-fiduciary'
    },
    {
      from: '/rustenburgkockstreetesmayhattingh',
      to: '/branch-office/rustenburg-kock-street-esmay-hattingh'
    },
    {
      from: '/rustenburgkockstreetlorrainevisagie',
      to: '/branch-office/rustenburg-kock-street-lorraine-visagie'
    },
    {
      from: '/rustenburgkockstreetjacquesjansevanrensburg',
      to: '/branch-office/rustenburg-kock-street-jacques-janse-van-rensburg'
    },
    {
      from: '/rustenburgkockstreetpieterstrydom',
      to: '/branch-office/rustenburg-kock-street-pieter-strydom'
    },
    {
      from: '/secundakiewietstreetfinancialplanningheinbruwer',
      to: '/branch-office/secunda-kiewiet-street-financial-planning-hein-bruwer'
    },
    {
      from: '/humansdorpkempstreetfinancialplanning',
      to: '/branch-office/humansdorp-kemp-street-financial-planning'
    },
    {
      from: '/kemptonparkshorttermcareljansevanvuren',
      to: '/branch-office/kempton-park-insure-carel-janse-van-vuren'
    },
    {
      from: '/sandton-grayston',
      to: '/branch-office/sandton-grayston'
    },
    {
      from: '/potchefstroomklerksdorpbothavilleshortterm',
      to: '/branch-office/potchefstroom-klerksdorp-insure'
    },
    {
      from: '/pretoriairenesovereigndrivefiduciaryservices',
      to: '/branch-office/tshwane-trust-and-estate-services-kotie-janse-van-rensburg'
    },
    {
      from: '/hermanusvillagesquare',
      to: '/branch-office/hermanus-village-square'
    },
    {
      from: '/jeffreys-bay-fountains-business-park',
      to: '/branch-office/jeffreys-bay-fountains-business-park'
    },
    {
      from: '/georgecentralshorttermmarkusfourie',
      to: '/branch-office/george-central-short-term-markus-fourie'
    },
    {
      from: '/graaffreinetbourkestreetfinancialplanning',
      to: '/branch-office/graaff-reinet-bourke-street-financial-planning'
    },
    {
      from: '/kemptonparkshorttermlouisvandenheever',
      to: '/branch-office/kempton-park-insure-louis-van-den-heever'
    },
    {
      from: '/montanafinforum',
      to: '/branch-office/montana-finforum-dr-van-der-merwe-road-short-term'
    },
    {
      from: '/ruimsiginsurenadineduvenage',
      to: '/branch-office/ruimsig-insure-nadine-duvenage'
    },
    {
      from: '/rustenburgkockstreetkarincronje',
      to: '/branch-office/rustenburg-kock-street-karin-cronje'
    },
    {
      from: '/steenberg-silverwood-close-thomas-torocsik',
      to: '/branch-office/steenberg-silverwood-close-thomas-torocsik'
    },
    {
      from: '/harrismithstuartstreetfinancialplanningjacquesbothma',
      to: '/branch-office/harrismith-stuart-street-financial-planning-jacques-bothma'
    },
    {
      from: '/johanduplessis',
      to: '/branch-office/bloemfontein-deale-road-financial-planning-johan-du-plessis'
    },
    {
      from: '/bloemfonteindealeroadfinancialplanningebenrautenbach',
      to: '/branch-office/bloemfontein-deale-road-financial-planning-eben-rautenbach'
    },
    {
      from: '/klerksdorpunit9bbuffelspark48buffeldoornstreetfinancialplanningdrienievosvictor',
      to: '/branch-office/klerksdorp-unit-9b-buffelspark-48-buffeldoorn-street-financial-planning-drienie-vos-victor'
    },
    {
      from: '/portelizabethparkdrivejeanlombard',
      to: '/branch-office/port-elizabeth-park-drive-jeanne-lombard'
    },
    {
      from: '/winelandstaniarossouw',
      to: '/branch-office/winelands-tania-rossouw'
    },
    {
      from: '/waterkloof-financial-planning-dulcie-weyks',
      to: '/branch-office/waterkloof-financial-planning-dulcie-weyks'
    },
    {
      from: '/waterkloof-financial-planning-gericke-zondagh',
      to: '/branch-office/waterkloof-financial-planning-gericke-zondagh'
    },
    {
      from: '/ruimsiginsureinabotha',
      to: '/branch-office/ruimsig-insure-ina-botha'
    },
    {
      from: '/mercedesbenzplace',
      to: '/branch-office/mercedes-benz-place'
    },
    {
      from: '/vryheidfinancialplanningmarnussteenkamp',
      to: '/branch-office/vryheid-financial-planning-marnus-steenkamp'
    },
    {
      from: '/stellenboschdorpstreetchrisleybotha',
      to: '/branch-office/stellenbosch-dorp-street-chrisley-botha'
    },
    {
      from: '/wolwespruit',
      to: '/branch-office/wolwespruit'
    },
    {
      from: '/r21-wealth-management-stellenbosch-technopark',
      to: '/branch-office/r21-wealth-management-stellenbosch-technopark'
    },
    {
      from: '/klerksdorpunit16buffelspark48buffeldoornstreetflamwood',
      to: '/branch-office/klerksdorp-unit-16-buffelspark-48-buffeldoorn-street-flamwood'
    },
    {
      from: '/r21-employee-benefits-irene',
      to: '/branch-office/r21-employee-benefits-irene'
    },
    {
      from: '/r21-wealth-management-irene',
      to: '/branch-office/r21-wealth-management-irene'
    },
    {
      from: '/r21-employee-benefits-stellenbosch-technopark',
      to: '/branch-office/r21-employee-benefits-stellenbosch-technopark'
    },
    {
      from: '/kloof-stockbroking',
      to: '/branch-office/kloof-stockbroking'
    },
    {
      from: '/musina',
      to: '/branch-office/musina'
    },
    {
      from: '/hoedspruitkoedoestreet',
      to: '/branch-office/hoedspruit-koedoe-street'
    },
    {
      from: '/willowbridgecarlcronjedriveshorttermraldavanwyk',
      to: '/branch-office/stellenbosch-church-street-ralda-van-wyk'
    },
    {
      from: '/shellybeachmarinedrivefinancialplanning',
      to: '/branch-office/shelly-beach-marine-drive-financial-planning'
    },
    {
      from: '/psgdeankerpotchefstroomshorttermcharldaubney',
      to: '/branch-office/de-anker-potchefstroom-insure'
    },
    {
      from: '/vryheidfinancialplanningkurtspangenberg',
      to: '/branch-office/vryheid-financial-planning-kurt-spangenberg'
    },
    {
      from: '/tzaneenagathastreetshorttermwilhelmgroenewald',
      to: '/branch-office/tzaneen-agatha-street-short-term-wilhelm-groenewald'
    },
    {
      from: '/rosebankpartnership',
      to: '/branch-office/rosebank-partnership'
    },
    {
      from: '/plettenbergbaygibbstreet',
      to: '/branch-office/plettenberg-bay-gibb-street'
    },
    {
      from: '/stellenboschdorpstreetantonoosthuizen',
      to: '/branch-office/stellenbosch-dorp-street-anton-oosthuizen'
    },
    {
      from: '/stellenboschdorpstreetfaangiliomee',
      to: '/branch-office/stellenbosch-dorp-street-faan-giliomee'
    },
    {
      from: '/stellenboschdorpstreetsimeonlaker',
      to: '/branch-office/stellenbosch-dorp-street-simeon-laker'
    },
    {
      from: '/stellenboschdorpstreettommymatthews',
      to: '/branch-office/stellenbosch-dorp-street-tommy-matthews'
    },
    {
      from: '/kloofbellevueroadkevinwaterston',
      to: '/branch-office/kloof-bellevue-road-kevin-waterston'
    },
    {
      from: '/kloofbellevueroadshaynewadsworth',
      to: '/branch-office/kloof-bellevue-road-shayne-wadsworth'
    },
    {
      from: '/kloofbellevueroadjarrydnieuwenhuis',
      to: '/branch-office/kloof-bellevue-road-jarryd-nieuwenhuis'
    },
    {
      from: '/kloofbellevueroadlukehoward',
      to: '/branch-office/kloof-bellevue-road-luke-howard'
    },
    {
      from: '/kloofbellevueroadthembisaluthuli',
      to: '/branch-office/kloof-bellevue-road-thembisa-luthuli'
    },
    {
      from: '/claremont-dreyer-street',
      to: '/branch-office/claremont-dreyer-street'
    },
    {
      from: '/schweizerrenekegiraffestreetshortterm',
      to: '/branch-office/schweizer-reneke-giraffe-street-short-term'
    },
    {
      from: '/jeffreysbayoosterlandstreetchrissiehurter',
      to: '/branch-office/jeffreys-bay-oosterland-street-short-term-chrissie-hurter'
    },
    {
      from: '/somersetwestmallringroadhealthcareneldacelliers',
      to: '/branch-office/somerset-west-mall-ring-road-healthcare-nelda-celliers'
    },
    {
      from: '/polokwaneisminiparkrenierneethling',
      to: '/branch-office/polokwane-ismini-park-renier-neethling'
    },
    {
      from: '/beaufortwestdonkinstreetjohanlochner',
      to: '/branch-office/beaufort-west-donkin-street-johan-lochner'
    },
    {
      from: '/velddrifkersbosstreet',
      to: '/branch-office/velddrif-kersbos-street'
    },
    {
      from: '/knysnaoldtollroadmarkusfourie',
      to: '/branch-office/knysna-old-toll-road'
    },
    {
      from: '/northclifffinancialplanningriaankemp',
      to: '/branch-office/northcliff-financial-planning-riaan-kemp'
    },
    {
      from: '/wolwespruit-short-term',
      to: '/branch-office/wolwespruit-short-term'
    },
    {
      from: '/mosselbaymeyerstreetpietervorster',
      to: '/branch-office/mossel-bay-meyer-street-pieter-vorster'
    },
    {
      from: '/ballitosimbithiofficeparkportfoliomanagementseanfouche',
      to: '/branch-office/ballito-simbithi-office-park-portfolio-management-sean-fouche'
    },
    {
      from: '/pietermaritzburgcascadesportfoliomanagement',
      to: '/branch-office/pietermaritzburg-cascades-portfolio-management'
    },
    {
      from: '/ballitosimbithiofficeparkportfoliomanagementrorylogan',
      to: '/branch-office/ballito-simbithi-office-park-portfolio-management-rory-logan'
    },
    {
      from: '/kloofbellevueroaddavechaplin',
      to: '/branch-office/kloof-bellevue-road-dave-chaplin'
    },
    {
      from: '/polokwaneisminiparkshorttermjuanpelser',
      to: '/branch-office/polokwane-ismini-park-short-term-juan-pelser'
    },
    {
      from: '/vineyards',
      to: '/branch-office/vineyards'
    },
    {
      from: '/northclifffinancialplanningklaasventer',
      to: '/branch-office/northcliff-financial-planning-klaas-venter'
    },
    {
      from: '/eastlondonstewartdrivemanuelferrezuelo',
      to: '/branch-office/east-london-stewart-drive-manuel-ferrezuelo'
    },
    {
      from: '/northclifffinancialplanningandrewvanderknaap',
      to: '/branch-office/northcliff-financial-planning-andrew-van-der-knaap'
    },
    {
      from: '/northclifffinancialplanninggerharddelange',
      to: '/branch-office/northcliff-financial-planning-gerhard-de-lange'
    },
    {
      from: '/northclifffinancialplanninglouisarynhoud',
      to: '/branch-office/northcliff-financial-planning-louisa-rynhoud'
    },
    {
      from: '/waterfallwealthtransfers',
      to: '/branch-office/waterfall-wealth-transfers'
    },
    {
      from: '/modimollethabombekiroad98financialplanninginabonnette',
      to: '/branch-office/modimolle-thabo-mbeki-road-98-financial-planning-ina-bonnette'
    },
    {
      from: '/waterfallwealthbulkadministration',
      to: '/branch-office/waterfall-wealth-bulk-administration'
    },
    {
      from: '/britswealthstephanvanbaalen',
      to: '/branch-office/brits-wealth-stephan-van-baalen'
    },
    {
      from: '/stellenboschdorpstreetjacquesoosthuizen',
      to: '/branch-office/stellenbosch-dorp-street-st-jacques-oosthuizen'
    },
    {
      from: '/magaliesnoordwealthzambezidrive',
      to: '/branch-office/magalies-noord-wealth-zambezi-drive'
    },
    {
      from: '/lichtenburgnelsonmandelaroadfinancialplanningbriangrainger',
      to: '/branch-office/lichtenburg-nelson-mandela-road-financial-planning-brian-grainger'
    },
    {
      from: '/sandtongraystonwealthrishprag',
      to: '/branch-office/sandton-grayston-wealth-rish-prag'
    },
    {
      from: '/ellisraswellstreethugomalan',
      to: '/branch-office/ellisras-well-street-hugo-malan'
    },
    {
      from: '/louistrichardtwealthchristophervanderwesthuizen',
      to: '/branch-office/louis-trichardt-wealth-christopher-van-der-westhuizen'
    },
    {
      from: '/roodepoort',
      to: '/branch-office/roodepoort'
    },
    {
      from: '/kloofbellevueroademployeebenefits',
      to: '/branch-office/kloof-bellevue-road-employee-benefits'
    },
    {
      from: '/citrusdalloopstreet',
      to: '/branch-office/citrusdal-loop-street'
    },
    {
      from: '/bloemfonteincentralwestdene',
      to: '/branch-office/bloemfontein-central-westdene'
    },
    {
      from: '/menlyn-stockbroking-gareth-griffiths',
      to: '/branch-office/menlyn-stockbroking-gareth-griffiths'
    },
    {
      from: '/midlandsemployeebenefitsconsultants',
      to: '/branch-office/midlands-employee-benefits-consultants'
    },
    {
      from: '/ruimsigstockbroking',
      to: '/branch-office/ruimsig-stockbroking'
    },
    {
      from: '/mercedesbenzstockbroking',
      to: '/branch-office/mercedes-benz-place-stockbroking'
    },
    {
      from: '/hermanusmagnoliafiduciary',
      to: '/branch-office/hermanus-magnolia-fiduciary'
    },
    {
      from: '/sandtonsandownwealthmanagementfinancialplanning',
      to: '/branch-office/sandton-sandown-wealth-management-and-financial-planning'
    },
    {
      from: '/lephalalepatrysstreetzeldaerasmus',
      to: '/branch-office/lephalale-patrys-street-zelda-erasmus'
    },
    {
      from: '/capetownstrandstreettuninroy',
      to: '/branch-office/cape-town-strand-street-tunin-roy'
    },
    {
      from: '/sovereigndriveemployeebenefits',
      to: '/branch-office/sovereign-drive-employee-benefits'
    },
    {
      from: '/pretorianewlandsloisavenuefiduciaryservices',
      to: '/branch-office/pretoria-newlands-lois-avenue-fiduciary-services'
    },
    {
      from: '/centurionmidstreaminsurejoslinnpienaar',
      to: '/branch-office/centurion-midstream-insure-joslinn-pienaar'
    },
    {
      from: '/bloemfonteincentralwestdeneshorttermandrewtlhatlogi',
      to: '/branch-office/bloemfontein-donald-murray-ave-short-term-andrew-tlhatlogi'
    },
    {
      from: '/oldoakbradgray',
      to: '/branch-office/old-oak-brad-gray'
    },
    {
      from: '/nelspruitshorttermfrancoisnel',
      to: '/branch-office/nelspruit-short-term-francois-nel'
    },
    {
      from: '/willowbridgecarlcronjedriveshorttermdieterprinsloo',
      to: '/branch-office/willowbridge-carl-cronje-drive-short-term-dieter-prinsloo'
    },
    {
      from: '/psgwaterfallwealthmanagement',
      to: '/branch-office/waterfall-wealth-management'
    },
    {
      from: '/oldoaksarethafouche',
      to: '/branch-office/old-oak-saretha-fouche'
    },
    {
      from: '/hydeparkshorttermkennethmooney',
      to: '/branch-office/hyde-park-short-term-kenneth-mooney'
    },
    {
      from: '/hydeparkshorttermdeansewpersad',
      to: '/branch-office/hyde-park-short-term-dean-sewpersad'
    },
    {
      from: '/magaliesnoordshorttermernieporter',
      to: '/branch-office/magalies-noord-short-term-ernie-porter'
    },
    {
      from: '/psgnorthgatepark',
      to: '/branch-office/northgate-park-financial-planning'
    },
    {
      from: '/paarlmainroad57healthcare',
      to: '/branch-office/paarl-main-road-57-healthcare'
    },
    {
      from: '/bedfordviewfinancialplanning',
      to: '/branch-office/bedfordview-financial-planning'
    },
    {
      from: '/bryanston',
      to: '/branch-office/bryanston'
    },
    {
      from: '/westernexco',
      to: '/branch-office/western-exco'
    },
    {
      from: '/westerntygerfallsunderwriting',
      to: '/branch-office/western-tygerfalls-underwriting'
    },
    {
      from: '/westerntygerfallsfinance',
      to: '/branch-office/western-tygerfalls-finance'
    },
    {
      from: '/westerntygerfallsclaimsprocurement',
      to: '/branch-office/western-tygerfalls-claims-and-procurement'
    },
    {
      from: '/westerntygerfallsprojectengineering',
      to: '/branch-office/western-tygerfalls-project-engineering'
    },
    {
      from: '/westerntygerfallsprojectengineering1',
      to: '/branch-office/western-tygerfalls-project-management'
    },
    {
      from: '/westerntygerfallsis',
      to: '/branch-office/western-tygerfalls-is'
    },
    {
      from: '/westerntygerfallsactuarial',
      to: '/branch-office/western-tygerfalls-actuarial'
    },
    {
      from: '/westerntygerfallsdistribution',
      to: '/branch-office/western-tygerfalls-distribution'
    },
    {
      from: '/westerntygerfallslegalcompliance',
      to: '/branch-office/western-tygerfalls-legal-and-compliance'
    },
    {
      from: '/westerntygerfallsops',
      to: '/branch-office/western-tygerfalls-ops'
    },
    {
      from: '/westerntygerfallsengineering',
      to: '/branch-office/western-tygerfalls-engineering'
    },
    {
      from: '/westerntygerfallsinternalaudit',
      to: '/branch-office/western-tygerfalls-internal-audit'
    },
    {
      from: '/westernmontanaclaimsandprocurement',
      to: '/branch-office/western-montana-claims-and-procurement'
    },
    {
      from: '/westernmontanadistribution',
      to: '/branch-office/western-montana-distribution'
    },
    {
      from: '/westernmontanaengineering',
      to: '/branch-office/western-montana-engineering'
    },
    {
      from: '/westernmontanaops',
      to: '/branch-office/western-montana-ops'
    },
    {
      from: '/westernmontanaunderwriting',
      to: '/branch-office/western-montana-underwriting'
    },
    {
      from: '/westernoudtshoorndistribution',
      to: '/branch-office/western-oudtshoorn-distribution'
    },
    {
      from: '/waterfallwesternprojectengineering',
      to: '/branch-office/waterfall-western-project-engineering'
    },
    {
      from: '/westernwindhoekclaimsandprocurement',
      to: '/branch-office/western-windhoek-claims-and-procurement'
    },
    {
      from: '/westernwindhoekdistribution',
      to: '/branch-office/western-windhoek-distribution'
    },
    {
      from: '/westernwindhoekops',
      to: '/branch-office/western-windhoek-ops'
    },
    {
      from: '/westernwindhoekunderwriting',
      to: '/branch-office/western-windhoek-underwriting'
    },
    {
      from: '/kemptonparkinsurelouisvandenheever',
      to: '/branch-office/bedfordview-insure-louis-van-den-heever'
    },
    {
      from: '/ruimsigholeinone',
      to: '/branch-office/ruimsig-hole-in-one'
    },
    {
      from: '/firestationsaxonwoldfinancialplanningrowanraath',
      to: '/branch-office/firestation-saxonwold-financial-planning-rowan-raath'
    },
    {
      from: '/ruimsigfinancialplanningdavidsnyman',
      to: '/branch-office/hole-in-one-ruimsig'
    },
    {
      from: '/potchefstroomretiefstreetfinancialplanning',
      to: '/branch-office/potchefstroom-retief-street-financial-planning'
    },
    {
      from: '/kloofbellevueroadgregkilmartin',
      to: '/branch-office/kloof-bellevue-road-greg-kilmartin'
    },
    {
      from: '/sandtongraystonjonathanfisher',
      to: '/branch-office/sandton-grayston-jonathan-fisher'
    },
    {
      from: '/meyertonfinancialplanningheinzkitshoff',
      to: '/branch-office/meyerton-financial-planning-heinz-kitshoff'
    },
    {
      from: '/meadowbrookfinancialplanningpaulbritz',
      to: '/branch-office/meadowbrook-financial-planning-paul-britz'
    },
    {
      from: '/benonieasttrixieappelman',
      to: '/branch-office/benoni-east-trixie-appelman'
    },
    {
      from: '/durbanvilleoxfordstreetstkurtpalm',
      to: '/branch-office/durbanville-oxford-street-st-kurt-palm'
    },
    {
      from: '/montanageorgecourtenaystreetst',
      to: '/branch-office/montana-george-courtenay-street-st'
    }
  ];

  constructor() {
  }

  checkIfCurrentUrlExists(currentUrl: string) {
    return this.urlsToRedirect.filter(alias => alias.from === currentUrl)[0];
  }

}

<div class="loader-center-container" *ngIf="isPageLoading">
  <div class="spinner-grow gold" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<!-- article overview & featured img -->
<div
  *ngIf="!isPageLoading"
  class="container-fluid mb-5 mt-4 svg-bg-r featured-img-bg"
>
  <div class="container-xl h-100">
    <div class="row">
      <div class="col-md-12">
        <!-- breacrumbs -->
        <div class="mb-4">
          <ul class="breadcrumb pl-0">
            <li>
              <a [routerLink]="['/news-and-publications']"
                >News & publications</a
              >
            </li>
            <icon-font name="icon-angle-right"></icon-font>
            <li class="breadcrumb-active">{{ content?.header?.headline }}</li>
          </ul>
        </div>
        <!-- /breacrumbs -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-5 py-0 py-md-4 order-1 order-md-0">
        <!-- article info -->
        <div>
          <!-- date -->
          <div class="mb-3">
            <h2 class="xlarge-heading mb-0">
              {{ content?.header?.publishDate | amDateFormat : "DD" }}
            </h2>
            <p class="body-small body-bold">
              {{ content?.header?.publishDate | amDateFormat : "MMMM YYYY" }}
            </p>
          </div>
          <!-- category tags -->
          <div class="card-tags my-3">
            <span
              *ngFor="let category of content?.header?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <!-- title -->
          <div>
            <h1 class="xlarge-heading">{{ content?.header?.headline }}</h1>
          </div>
          <!-- author -->
          <div
            *ngFor="let author of content?.header?.author"
            class="d-flex flex-row justify-content-start align-items-center my-4"
          >
            <img
              (click)="goToAdvisorProfile(author.myBaseId)"
              [src]="author.image"
              alt="Avatar"
              class="image-medium-avatar pointer mr-3"
            />
            <div class="d-flex flex-column">
              <p
                (click)="goToAdvisorProfile(author.myBaseId)"
                class="body-med body-bold pointer cyan mb-0"
              >
                {{ author.name }}
              </p>
              <p class="body-small mb-0">
                {{ author.division }}
              </p>
            </div>
          </div>
          <!-- article excerpt -->
          <div>
            <p [innerHTML]="content?.header?.excerpt"></p>
          </div>
        </div>
        <!-- /article info -->

        <!--social icons-->
        <div class="row d-flex text-left">
          <button class="button simple-icon" (click)="shareToMail()">
            <icon-font
              name="icon-envelope"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToLinkedIn()">
            <icon-font
              name="icon-linkedin"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToTwitter()">
            <icon-font
              name="icon-twitter"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToFb()">
            <icon-font
              name="icon-facebook"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
          <button class="button simple-icon" (click)="shareToWhatsApp()">
            <icon-font
              name="icon-whatsapp"
              colour="cyan"
              class="body-large cyan"
            ></icon-font>
          </button>
        </div>
        <!--social icons-->
      </div>

      <div class="col-md-6 col-lg-6 offset-lg-1 order-0 order-md-1">
        <!-- featured img -->
        <img
          [src]="content?.header?.image"
          class="featured-img--article significant-raise"
        />
        <!-- /featured img -->
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-10 offset-md-1">
        <blockquote *ngIf="quote" class="blockquote">
          <span class="cyan">“</span>
          {{ quote }}
          <span class="cyan">”</span>
        </blockquote>
      </div>
    </div>
  </div>
</div>
<!-- /article overview & featured img -->

<!--article content-->
<div *ngIf="!isPageLoading" class="container-fluid pt-4 pt-md-5">
  <div class="container-xl">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h2 class="large-heading">
          {{ content?.body?.sections[1]?.sections[0]?.heading }}
        </h2>
        <p [innerHTML]="content?.body?.sections[1]?.sections[0]?.content"></p>
      </div>
    </div>
  </div>
</div>
<!--article content-->

<!-- Recommended news  cards section -->
<div
  class="container-fluid py-5 my-5 slate-bg-10"
  *ngIf="
    content?.body?.sections[2]?.heading != null ||
    (content?.body?.sections[2]?.heading != undefined && !isPageLoading)
  "
>
  <div class="container-xl">
    <div>
      <h2 class="mb-4">{{ content?.body?.sections[2]?.heading }}</h2>
    </div>
    <div class="card-deck">
      <div
        class="card news--card"
        *ngFor="let news of content?.body?.sections[2]?.articles"
      >
        <img class="card-img-top" [src]="news?.image" alt="Card image cap" />
        <div class="card-body">
          <div class="card-tags pb-2">
            <span
              *ngFor="let category of news?.categories"
              role="button"
              class="basic-badge"
              data-toggle="modal"
              data-target="#modal-article-tags"
              (click)="
                pageService.SetArticle(category); $event.preventDefault()
              "
              >{{ category }}</span
            >
          </div>
          <h5 class="small-heading">{{ news?.headline }}</h5>
          <p class="body-small" [innerHTML]="news?.excerpt"></p>
          <a [routerLink]="news?.uri" class="textlink">Read more</a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Recommended news  cards section -->

import { Component, OnInit, Renderer2 } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ArticleService } from "src/app/services/article.service";
import { PageService } from "src/app/services/page.service";
import { ArticlesPage } from "src/app/models/content/news-and-publications/articles.model";
import { SeoService } from "src/app/services/socialLinks.service";
import { finalize } from "rxjs";
@Component({
  templateUrl: "./news-and-publication-article.component.html",
})
export class NewsAndPublicationArticleComponent implements OnInit {
  public content: any = {};
  public newsArticle: string;
  public name: string;
  public division: string;

  public fragment: string;

  public shareHeadline: string;
  public quote: string;
  public isPageLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public pageService: PageService,
    public seoService: SeoService,
    private router: Router,
    private renderer: Renderer2
  ) { }

  public ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.newsArticle = params.newsArticle;

      this.route.url.subscribe((url) => {
        switch (url[0]?.toString()) {
          case "articles": {
            this.pageService
              .GetPage<ArticlesPage>(
                this.pageService.URIs.featureArticleBase +
                "/" +
                this.newsArticle
              )
              .pipe(
                finalize(() => {
                  this.isPageLoading = false;
                })
              )
              .subscribe((content) => {
                this.content = new ArticlesPage(content);
                this.shareHeadline = this.content.header.headline.replace(
                  "&",
                  "%26"
                ); // TODO: Temp workaround for & characters
                this.seoService.updateMetaInfoForPage(
                  this.content.seo.title,
                  window.location.href,
                  this.content?.header?.image,
                  this.content.seo.description,
                  "article"
                );

                let hasQuote = this.content?.body?.sections.filter(
                  (x) => x.type === "quote"
                );
                if (hasQuote[0]) this.quote = hasQuote[0].content;
              });

            this.route.fragment.subscribe((fragment: string) => {
              this.fragment = fragment;
            });
            break;
          }
          case "asset-management": {
            this.pageService
              .GetPage<ArticlesPage>(
                this.pageService.URIs.assetManagementArticlesBase +
                "/" +
                this.newsArticle
              )
              .pipe(
                finalize(() => {
                  this.isPageLoading = false;
                })
              )
              .subscribe((content) => {
                this.content = new ArticlesPage(content);
                this.shareHeadline = this.content.header.headline.replace(
                  "&",
                  "%26"
                );
                this.seoService.updateMetaInfoForPage(
                  this.content.seo.title,
                  window.location.href,
                  this.content?.header?.image,
                  this.content.seo.description,
                  "article"
                );

                let hasQuote = this.content?.body?.sections.filter(
                  (x) => x.type === "quote"
                );
                if (hasQuote[0]) this.quote = hasQuote[0].content;
              });

            this.route.fragment.subscribe((fragment: string) => {
              this.fragment = fragment;
            });

            break;
          }
          case "wealth": {
            this.pageService
              .GetPage<ArticlesPage>(
                this.pageService.URIs.wealthArticlesBase +
                "/" +
                this.newsArticle
              )
              .pipe(
                finalize(() => {
                  this.isPageLoading = false;
                })
              )
              .subscribe((content) => {
                this.content = new ArticlesPage(content);
                this.shareHeadline = this.content.header.headline.replace(
                  "&",
                  "%26"
                );
                this.seoService.updateMetaInfoForPage(
                  this.content.seo.title,
                  window.location.href,
                  this.content?.header?.image,
                  this.content.seo.description,
                  "article"
                );
                this.shareHeadline = this.content.header.headline?.replace(
                  "&",
                  "%26"
                );

                let hasQuote = this.content?.body?.sections.filter(
                  (x) => x.type === "quote"
                );
                if (hasQuote[0]) this.quote = hasQuote[0].content;
              });

            this.route.fragment.subscribe((fragment: string) => {
              this.fragment = fragment;
            });

            break;
          }
          case "psg-asset-management": {
            this.pageService
              .GetPage<ArticlesPage>(
                this.pageService.URIs.assetManagementArticleBase +
                "/" +
                this.newsArticle
              )
              .pipe(
                finalize(() => {
                  this.isPageLoading = false;
                })
              )
              .subscribe((content) => {
                this.content = new ArticlesPage(content);
                this.shareHeadline = this.content.header.headline.replace(
                  "&",
                  "%26"
                ); // TODO: Temp workaround for & characters
                this.seoService.updateMetaInfoForPage(
                  this.content.seo.title,
                  window.location.href,
                  this.content?.header?.image,
                  this.content.seo.description,
                  "article"
                );

                let hasQuote = this.content?.body?.sections.filter(
                  (x) => x.type === "quote"
                );
                if (hasQuote[0]) this.quote = hasQuote[0].content;
              });

            this.route.fragment.subscribe((fragment: string) => {
              this.fragment = fragment;
            });
            break;
          }
        }
      });
    });

    this.seoService.setJsonLd(this.renderer, null);
  }

  //hashtag/anchor scrolling
  ngAfterViewInit() {
    this.scrollTimer();
  }

  public goToAdvisorProfile(id) {
    if (id == null) {
      this.router.navigateByUrl("/");
    }
    else {
      this.router.navigateByUrl(`financial-advice/adviser-profile/${id}`);
    }
  }

  public async scrollTimer() {
    setTimeout(() => {
      let doc = document.getElementById(this.fragment);
      if (doc != undefined) {
        window.scrollTo(0, doc.offsetTop - 120);
      } else {
        this.scrollTimer();
      }
    }, 1000);
  }

  shareToFb() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href
    );
  }

  shareToTwitter() {
    window.open(
      "https://twitter.com/intent/tweet?text=" + window.location.href
    );
  }

  shareToLinkedIn() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
      window.location.href
    );
  }

  shareToMail() {
    window.open(
      `mailto:?subject=${this.shareHeadline}&body=` + window.location.href
    );
  }

  shareToWhatsApp() {
    window.open("https://api.whatsapp.com/send?text=" + window.location.href);
  }
}

import { BrowserModule, Meta, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CarouselModule } from "ngx-owl-carousel-o";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ElementsModule } from "elements";
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout/default-layout.component';
import { StandaloneLayoutComponent } from './layouts/standalone-layout/standalone-layout/standalone-layout.component';
import { HeaderComponent } from "./components/shared/header/header.component";
import { FooterComponent } from "./components/shared/footer/footer.component";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { NewsAndPublicationsComponent } from "./components/news-and-publications/news-and-publications.component";
import { NewsAndPublicationArticleComponent } from "./components/news-and-publications/news-and-publication-article/news-and-publication-article.component";
import { HttpClientModule } from "@angular/common/http";
import { LoggerService } from "./services/base/logger.service";
import { SettingsService } from "./services/base/settings.service";
import { ArticleService } from "./services/article.service";
import { PageService } from "./services/page.service";
import { HowToInvestComponent } from "./components/save-and-invest/how-to-invest/how-to-invest.component";
import { OffshoreVoluntaryInvestmentPlanComponent } from "./components/save-and-invest/investment-products/offshore-voluntary-investment-plan/offshore-voluntary-investment-plan.component";
import { GlossaryComponent } from "./components/support/glossary/glossary.component";
import { LegalComponent } from "./components/support/legal/legal.component";
import { ReportsAndNoticesComponent } from "./components/support/reports-and-notices/reports-and-notices.component";
import { SearchResultsComponent } from "./components/search-results/search-results.component";
import { FormsAndFactsSheetsComponent } from "./components/support/forms-and-facts-sheets/forms-and-facts-sheets.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { FundFactSheetComponent } from "./components/fund-pages/fund-fact-sheet/fund-fact-sheet.component";
import { DiuArticleComponent } from "./components/news-and-publications/diu-article/diu-article.component";
import { DiuArticleStaticComponent } from "./components/news-and-publications/diu-article-static/diu-article-static.component";
import { MarketIndicatorComponent } from "./components/news-and-publications/market-indicator/market-indicator.component";
import { SharePriceComponent } from "./components/news-and-publications/share-price/share-price.component";
import { DIUVideoComponent } from "./components/news-and-publications/diu-video/diu-video.component";
import { SaveAndInvestComponent } from "./components/save-and-invest/save-and-invest.component";
import { SavingsComponent } from "./components/save-and-invest/savings/savings.component";
import { InvestmentProductsComponent } from "./components/save-and-invest/investment-products/investment-products.component";
import { AllPsgFundsComponent } from "./components/fund-pages/all-psg-funds/all-psg-funds.component";
import { PsgMultiManagedFundsComponent } from "./components/fund-pages/psg-multi-managed-funds/psg-multi-managed-funds.component";
import { PsgSingleManagedFundsComponent } from "./components/fund-pages/psg-single-managed-funds/psg-single-managed-funds.component";
import { AllFundsComponent } from "./components/fund-pages/all-funds/all-funds.component";
import { RetirementPlanningComponent } from "./components/save-and-invest/retirement-planning/retirement-planning.component";
import { RetirementProductsComponent } from "./components/save-and-invest/retirement-products/retirement-products.component";
import { StockbrokingComponent } from "./components/save-and-invest/stockbroking/stockbroking.component";
import { BusinessRetirementServicesComponent } from "./components/save-and-invest/business-retirement-services/business-retirement-services.component";
import { CompareInvestmentProductsComponent } from "./components/save-and-invest/compare-investment-products/compare-investment-products.component";
import { VoluntaryInvestmentPlanComponent } from "./components/save-and-invest/investment-products/voluntary-investment-plan/voluntary-investment-plan.component";
import { TaxFreeInvestmentPlanComponent } from "./components/save-and-invest/investment-products/tax-free-investment-plan/tax-free-investment-plan.component";
import { EndowmentInvestmentPlanComponent } from "./components/save-and-invest/investment-products/endowment-investment-plan/endowment-investment-plan.component";
import { RetirementAnnuityComponent } from "./components/save-and-invest/retirement-products/retirement-annuity/retirement-annuity.component";
import { LivingAnnuityComponent } from "./components/save-and-invest/retirement-products/living-annuity/living-annuity.component";
import { PreservationFundComponent } from "./components/save-and-invest/retirement-products/preservation-fund/preservation-fund.component";
import { AgmCoreModule } from "@agm/core";
import { PsgFundsLocalComponent } from "./components/fund-pages/fund-tables/psg-funds-local/psg-funds-local.component";
import { PsgFundsGlobalComponent } from "./components/fund-pages/fund-tables/psg-funds-global/psg-funds-global.component";
import { PsgFundsOtherCurrenciesComponent } from "./components/fund-pages/fund-tables/psg-funds-other-currencies/psg-funds-other-currencies.component";
import { AllFundsTableComponent } from "./components/fund-pages/fund-tables/all-funds-table/all-funds-table.component";
import { MmFundsLocalComponent } from "./components/fund-pages/fund-tables/mm-funds-local/mm-funds-local.component";
import { MmFundsGlobalComponent } from "./components/fund-pages/fund-tables/mm-funds-global/mm-funds-global.component";
import { MmFundsOtherCurrenciesComponent } from "./components/fund-pages/fund-tables/mm-funds-other-currencies/mm-funds-other-currencies.component";
import { MmFundsHouseViewComponent } from "./components/fund-pages/fund-tables/mm-funds-house-view/mm-funds-house-view.component";
import { SmFundsLocalComponent } from "./components/fund-pages/fund-tables/sm-funds-local/sm-funds-local.component";
import { SmFundsGlobalComponent } from "./components/fund-pages/fund-tables/sm-funds-global/sm-funds-global.component";
import { StockbrokingOptionsComponent } from "./components/save-and-invest/stockbroking/stockbroking-options/stockbroking-options.component";
import { TradingStepsComponent } from "./components/save-and-invest/stockbroking/trading-steps/trading-steps.component";
import { FinancialAdviceComponent } from "./components/financial-advice/financial-advice.component";
import { AdviserProfileComponent } from "./components/financial-advice/adviser-profile/adviser-profile.component";
import { FindAnAdviserComponent } from "./components/financial-advice/find-an-adviser/find-an-adviser.component";
import { BranchOfficeComponent } from "./components/financial-advice/branch-office/branch-office.component";
import { BranchOfficeNewsComponent } from "./components/financial-advice/branch-office-news/branch-office-news.component";
import { InsuranceComponent } from "./components/insurance/insurance.component";
import { ForYourEmployeesComponent } from "./components/insurance/for-your-employees/for-your-employees.component";
import { ForYourBusinessComponent } from "./components/insurance/for-your-business/for-your-business.component";
import { AboutPsgInsureComponent } from "./components/about-us/about-psg-insure/about-psg-insure.component";
import { ForYourselftComponent } from "./components/insurance/for-yourselft/for-yourselft.component";
import { MedicalAidComponent } from "./components/insurance/for-yourselft/medical-aid/medical-aid.component";
import { GapCoverComponent } from "./components/insurance/for-yourselft/gap-cover/gap-cover.component";
import { LifeCoverComponent } from "./components/insurance/for-yourselft/life-cover/life-cover.component";
import { IncomeProductsComponent } from "./components/insurance/for-yourselft/income-products/income-products.component";
import { DisabilityCoverComponent } from "./components/insurance/for-yourselft/disability-cover/disability-cover.component";
import { DreadDiseaseCoverComponent } from "./components/insurance/for-yourselft/dread-disease-cover/dread-disease-cover.component";
import { ForYourThingsComponent } from "./components/insurance/for-your-things/for-your-things.component";
import { MotorCoverComponent } from "./components/insurance/for-your-things/motor-cover/motor-cover.component";
import { HomeContentsPossessionsComponent } from "./components/insurance/for-your-things/home-contents-possessions/home-contents-possessions.component";
import { LiabilityAccidentCoverComponent } from "./components/insurance/for-your-things/liability-accident-cover/liability-accident-cover.component";
import { ValueAddInsuranceCoverComponent } from "./components/insurance/for-your-things/value-add-insurance-cover/value-add-insurance-cover.component";
import { InvestorRelationsComponent } from "./components/investor-relations/investor-relations.component";
import { InvestorInformationComponent } from "./components/investor-relations/investor-information/investor-information.component";
import { CorporateGovernanceComponent } from "./components/investor-relations/corporate-governance/corporate-governance.component";
import { SensAndShareDataComponent } from "./components/investor-relations/sens-and-share-data/sens-and-share-data.component";
import { PsgWealthComponent } from "./components/about-us/psg-wealth/psg-wealth.component";
import { PsgAssetManagementComponent } from "./components/about-us/psg-asset-management/psg-asset-management.component";
import { AssetManagementNewsAndPublicationsComponent } from './components/about-us/psg-asset-management/news-and-publications/asset-management-news-and-publications/asset-management-news-and-publications.component';
import { AdvisorService } from "./services/advisor.service";
import { RecaptchaModule } from "ng-recaptcha";
import { AdviceForYourselfComponent } from "./components/financial-advice/advice-for-yourself/advice-for-yourself.component";
import { AdviceForYourEstateComponent } from "./components/financial-advice/advice-for-your-estate/advice-for-your-estate.component";
import { AdviceProcessStepsComponent } from "./components/financial-advice/advice-for-yourself/advice-process-steps/advice-process-steps.component-archive";
import { AdviceForYourEmployeesComponent } from "./components/financial-advice/advice-for-your-employees/advice-for-your-employees.component";
import { ArchiveComponent } from "./components/about-us/archive/archive.component";
import { CareersComponent } from "./components/careers/careers/careers.component";
import { GraduateProgrammeComponent } from "./components/careers/graduate-programme/graduate-programme.component";
import { BecomeAnAdviserComponent } from "./components/careers/become-an-adviser/become-an-adviser.component";
import { PartnerWithPsgComponent } from "./components/careers/partner-with-psg/partner-with-psg.component";
import { FinancialToolsComponent } from "./components/support/financial-tools/financial-tools.component";
import { EventsAndWebinarsComponent } from "./components/news-and-publications/events-and-webinars/events-and-webinars.component";
import { FaqComponent } from "./components/support/faq/faq.component";
import { DiuEconomicCalenderComponent } from "./components/news-and-publications/diu-economic-calender/diu-economic-calender.component";
import { SearchComponent } from "./components/search/search.component";
import { HighlightSearch } from "./pipes/highlightSearched.pipe";
import { SafePipe } from "./pipes/safe-url.pipe";
import { DailyInvestmentService } from "./services/dailyInvestment/daily-investment.service";
import { DynamicContentTemplateComponent } from "./components/shared/dynamic-content-template-builder/dynamic-content-template/dynamic-content-template.component";
import { DynamicContentTemplateBuilderComponent } from "./components/shared/dynamic-content-template-builder/dynamic-content-template-builder.component";
import { GoalCalculatorComponent } from "./components/save-and-invest/goal-calculator/goal-calculator.component";
import { RetirementCalculatorComponent } from "./components/save-and-invest/retirement-calculator/retirement-calculator.component";
import { MomentModule } from "angular2-moment";
import { CompareInsuranceComponent } from "./components/insurance/compare-insurance/compare-insurance.component";
import { SensTradeService } from "./services/sens/sens-trade.service";
import { SensArticlesComponent } from "./components/investor-relations/send-articles/sens-articles.component";
import { NgChartsModule, ThemeService } from "ng2-charts";
import { SearchService } from "./services/search.service";
import { CampaignV1Component } from "./components/campaign-pages/campaign-v1/campaign-v1.component";
import { CampaignV2Component } from "./components/campaign-pages/campaign-v2/campaign-v2.component";
import { FACEBOOK_PIXEL_PROVIDER } from "./services/pixel-services/facebook-pixel/facebook-pixel.provider";
import { EducateYourselfComponent } from "./components/save-and-invest/educate-yourself/educate-yourself.component";
import { LastesNewsCarouselComponent } from "./components/financial-advice/branch-office/lastes-news-carousel/lastes-news-carousel.component";
import { FormattingService } from "src/app/services/formatting.service";
import { OverviewTableComponent } from "./components/fund-pages/fund-tables/overview-table/overview-table.component";
import { PriceAndPerformanceTableComponent } from "./components/fund-pages/fund-tables/price-and-performance-table/price-and-performance-table.component";
import { environment } from "src/environments/environment";
import { FalconComponent } from "./components/campaign-pages/falcon/falcon.component";
import { DownloadsComponent } from "./components/contact-us/downloads/downloads.component";
import { EducationCalculatorComponent } from "./components/save-and-invest/education-calculator/education-calculator.component";
import { InvestmentCalculatorsComponent } from "./components/save-and-invest/investment-calculators/investment-calculators.component";
import { HeroComponent } from "./components/save-and-invest/investment-calculators/hero/hero.component";
import { AboutSectionComponent } from "./components/save-and-invest/investment-calculators/about-section/about-section.component";
import { InfoCardsSectionComponent } from "./components/save-and-invest/investment-calculators/info-cards-section/info-cards-section.component";
import { CallMeBackModalComponent } from "./components/shared/call-me-back-modal/call-me-back-modal.component";
import { ArticleTagsModalComponent } from "./components/shared/article-tags-modal/article-tags-modal.component";
import { LandingPBannerCarouselComponent } from "./components/landing-page/landing-p-banner-carousel/landing-p-banner-carousel.component";
import { OfficeArticlesComponent } from "./components/news-and-publications/office-articles/office-articles.component";
import { ESGComponent } from "./components/about-us/psg-asset-management/esg/esg.component";
import { PsgFundsLocalStaticComponent } from "./components/news-and-publications/diu-article-static/psg-funds-local-static/psg-funds-local-static.component";
import { MarketIndicatorStaticComponent } from "./components/news-and-publications/diu-article-static/market-indicator-static/market-indicator-static.component";
import { PsgFundsGlobalStaticComponent } from "./components/news-and-publications/diu-article-static/psg-funds-global-static/psg-funds-global-static.component";
import { PsgFundsOtherCurrenciesStaticComponent } from "./components/news-and-publications/diu-article-static/psg-funds-other-currencies-static/psg-funds-other-currencies-static.component";
import { MmFundsHouseStaticComponent } from "./components/news-and-publications/diu-article-static/mm-funds-house-static/mm-funds-house-static.component";
import { ClickOutsideModule } from "ng-click-outside";
import { EmployeeComponent } from './components/careers/employee/employee.component';
import { FrequentlyAskedQuestionsComponent } from './components/news-and-publications/research-hub/shared/frequently-asked-questions/frequently-asked-questions.component';
import { AvoidingMistakesComponent } from './components/news-and-publications/research-hub/shared/avoiding-mistakes/avoiding-mistakes.component';
import { LatestReportsComponent } from './components/news-and-publications/research-hub/shared/latest-reports/latest-reports.component';
import { PreviousPublicationsComponent } from './components/news-and-publications/research-hub/shared/previous-publications/previous-publications.component';
import { WeekAheadComponent } from './components/news-and-publications/research-hub/week-ahead/week-ahead.component';
import { MarketsManualComponent } from './components/news-and-publications/research-hub/markets-manual/markets-manual.component';
import { MacroEconomicInsightsComponent } from './components/news-and-publications/research-hub/macro-economic-insights/macro-economic-insights.component';
import { FundResearchComponent } from './components/news-and-publications/research-hub/fund-research/fund-research.component';
import { EquityResearchComponent } from './components/news-and-publications/research-hub/equity-research/equity-research.component';
import { LatestReportFilterPipe } from "./pipes/latest-reports.pipe";
import { InTheMediaComponent } from './components/news-and-publications/research-hub/in-the-media/in-the-media.component';
import { ResearchInsightsComponent } from './components/news-and-publications/research-hub/research-insights/research-insights.component';
import { SolutionInsightsComponent } from './components/news-and-publications/research-hub/solution-insights/solution-insights.component';
import { HouseViewEquityPortfoliosComponent } from './components/news-and-publications/research-hub/house-view-equity-portfolios/house-view-equity-portfolios.component';
import { FundOfFundsComponent } from './components/news-and-publications/research-hub/fund-of-funds/fund-of-funds.component';
import { PreviousReportsComponent } from './components/news-and-publications/research-hub/shared/previous-reports/previous-reports.component';
import { ReportsComponent } from './components/news-and-publications/research-hub/reports/reports.component';
import { AssetManagementFormsComponent } from './components/about-us/psg-asset-management/forms/asset-management-forms/asset-management-forms.component';
import { AssetManagementFactSheetsComponent } from './components/about-us/psg-asset-management/fact-sheets/asset-management-fact-sheets/asset-management-fact-sheets.component';
import { StartYourJourneyComponent } from './components/start-your-journey/start-your-journey/start-your-journey.component';
import { LeadAdviserComponent } from './components/shared/lead-forms/lead-adviser/lead-adviser.component';
import { LeadAssetManagementClientComponent } from './components/shared/lead-forms/lead-asset-management-client/lead-asset-management-client.component';
import { LeadAssetManagementClientAdviserComponent } from './components/shared/lead-forms/lead-asset-management-client-adviser/lead-asset-management-client-adviser.component';
import { LeadAssetManagementClientBulkInvestorComponent } from './components/shared/lead-forms/lead-asset-management-client-bulk-investor/lead-asset-management-client-bulk-investor.component';
import { LeadCareersComponent } from './components/shared/lead-forms/lead-careers/lead-careers.component';
import { LeadClientComponent } from './components/shared/lead-forms/lead-client/lead-client.component';
import { LeadClientServicesComponent } from './components/shared/lead-forms/lead-client-services/lead-client-services.component';
import { LeadClientThinkBigComponent } from './components/shared/lead-forms/lead-client-think-big/lead-client-think-big.component';
import { LeadContactMeComponent } from './components/shared/lead-forms/lead-contact-me/lead-contact-me.component';
import { LeadInsuranceCallComponent } from './components/shared/lead-forms/lead-insurance-call/lead-insurance-call.component';
import { LeadInsuranceQuoteComponent } from './components/shared/lead-forms/lead-insurance-quote/lead-insurance-quote.component';
import { MoneyMarketFundsComponent } from './components/fund-pages/money-market-funds/money-market-funds.component';
import { ThinkBigSouthAfricaComponent } from './components/news-and-publications/our-events/think-big-south-africa/think-big-south-africa.component';
import { DiuArticleNavigationComponent } from './components/news-and-publications/diu-article-navigation/diu-article-navigation.component';
import { BannerComponent } from './components/shared/banner/banner.component';
import { NotFoundComponent } from './components/shared/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    StandaloneLayoutComponent,
    AllFundsComponent,
    AboutUsComponent,
    ContactUsComponent,
    HeaderComponent,
    FooterComponent,
    LandingPageComponent,
    NewsAndPublicationsComponent,
    NewsAndPublicationArticleComponent,
    HowToInvestComponent,
    OffshoreVoluntaryInvestmentPlanComponent,
    GlossaryComponent,
    LegalComponent,
    ReportsAndNoticesComponent,
    SearchResultsComponent,
    FormsAndFactsSheetsComponent,
    AboutUsComponent,
    DIUVideoComponent,
    PsgFundsLocalComponent,
    PsgFundsGlobalComponent,
    PsgFundsOtherCurrenciesComponent,
    FundFactSheetComponent,
    DiuArticleComponent,
    DiuArticleStaticComponent,
    MarketIndicatorComponent,
    SharePriceComponent,
    SaveAndInvestComponent,
    SavingsComponent,
    InvestmentProductsComponent,
    AllPsgFundsComponent,
    PsgMultiManagedFundsComponent,
    PsgSingleManagedFundsComponent,
    AllFundsComponent,
    RetirementPlanningComponent,
    RetirementProductsComponent,
    StockbrokingComponent,
    BusinessRetirementServicesComponent,
    CompareInvestmentProductsComponent,
    VoluntaryInvestmentPlanComponent,
    TaxFreeInvestmentPlanComponent,
    EndowmentInvestmentPlanComponent,
    RetirementAnnuityComponent,
    LivingAnnuityComponent,
    PreservationFundComponent,
    AllFundsTableComponent,
    MmFundsLocalComponent,
    MmFundsGlobalComponent,
    MmFundsOtherCurrenciesComponent,
    MmFundsHouseViewComponent,
    SmFundsLocalComponent,
    SmFundsGlobalComponent,
    StockbrokingOptionsComponent,
    TradingStepsComponent,
    FinancialAdviceComponent,
    AdviserProfileComponent,
    FindAnAdviserComponent,
    BranchOfficeComponent,
    BranchOfficeNewsComponent,
    InsuranceComponent,
    ForYourEmployeesComponent,
    ForYourBusinessComponent,
    AboutPsgInsureComponent,
    ForYourselftComponent,
    MedicalAidComponent,
    GapCoverComponent,
    LifeCoverComponent,
    IncomeProductsComponent,
    DisabilityCoverComponent,
    DreadDiseaseCoverComponent,
    ForYourThingsComponent,
    MotorCoverComponent,
    HomeContentsPossessionsComponent,
    LiabilityAccidentCoverComponent,
    ValueAddInsuranceCoverComponent,
    InvestorRelationsComponent,
    InvestorInformationComponent,
    CorporateGovernanceComponent,
    SensAndShareDataComponent,
    PsgWealthComponent,
    PsgAssetManagementComponent,
    AssetManagementNewsAndPublicationsComponent,
    AssetManagementFormsComponent,
    AssetManagementFactSheetsComponent,
    AdviceForYourselfComponent,
    AdviceForYourEstateComponent,
    AdviceProcessStepsComponent,
    AdviceForYourEmployeesComponent,
    ArchiveComponent,
    CareersComponent,
    GraduateProgrammeComponent,
    BecomeAnAdviserComponent,
    PartnerWithPsgComponent,
    FinancialToolsComponent,
    EventsAndWebinarsComponent,
    FaqComponent,
    DiuEconomicCalenderComponent,
    SearchComponent,
    HighlightSearch,
    SensArticlesComponent,
    DynamicContentTemplateComponent,
    DynamicContentTemplateBuilderComponent,
    OverviewTableComponent,
    PriceAndPerformanceTableComponent,
    DownloadsComponent,
    GoalCalculatorComponent,
    RetirementCalculatorComponent,
    CompareInsuranceComponent,
    CampaignV1Component,
    CampaignV2Component,
    EducateYourselfComponent,
    LastesNewsCarouselComponent,
    FalconComponent,
    EducationCalculatorComponent,
    InvestmentCalculatorsComponent,
    HeroComponent,
    AboutSectionComponent,
    InfoCardsSectionComponent,
    CallMeBackModalComponent,
    ArticleTagsModalComponent,
    LandingPBannerCarouselComponent,
    SafePipe,
    OfficeArticlesComponent,
    ESGComponent,
    PsgFundsLocalStaticComponent,
    MarketIndicatorStaticComponent,
    PsgFundsGlobalStaticComponent,
    PsgFundsOtherCurrenciesStaticComponent,
    MmFundsHouseStaticComponent,
    EmployeeComponent,
    FrequentlyAskedQuestionsComponent,
    AvoidingMistakesComponent,
    LatestReportsComponent,
    PreviousPublicationsComponent,
    WeekAheadComponent,
    MarketsManualComponent,
    MacroEconomicInsightsComponent,
    FundResearchComponent,
    EquityResearchComponent,
    LatestReportFilterPipe,
    InTheMediaComponent,
    ResearchInsightsComponent,
    SolutionInsightsComponent,
    HouseViewEquityPortfoliosComponent,
    FundOfFundsComponent,
    PreviousReportsComponent,
    ReportsComponent,
    StartYourJourneyComponent,
    LeadAdviserComponent,
    LeadAssetManagementClientComponent,
    LeadAssetManagementClientAdviserComponent,
    LeadAssetManagementClientBulkInvestorComponent,
    LeadCareersComponent,
    LeadClientComponent,
    LeadClientServicesComponent,
    LeadClientThinkBigComponent,
    LeadContactMeComponent,
    LeadInsuranceCallComponent,
    LeadInsuranceQuoteComponent,
    MoneyMarketFundsComponent,
    ThinkBigSouthAfricaComponent,
    DiuArticleNavigationComponent,
    BannerComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ElementsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule,
    FormsModule,
    RecaptchaModule,
    MomentModule,
    NgChartsModule,
    ClickOutsideModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GoogleApiKey,
    }),
  ],
  providers: [
    Meta,
    Title,
    LoggerService,
    SettingsService,
    ArticleService,
    PageService,
    SearchService,
    AdvisorService,
    DailyInvestmentService,
    SensTradeService,
    ThemeService,
    FACEBOOK_PIXEL_PROVIDER,
    FormattingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
